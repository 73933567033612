import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import Swal from "sweetalert2";

function PrivateRoute({ children }) {
	const navigate = useNavigate();

	const MIN_JWT_LENGTH = 20;

	const auth = localStorage.getItem("auth");
	const access = localStorage.getItem("access");
	const refresh = localStorage.getItem("refresh");

	const isValidToken = (token) => token && token.length > MIN_JWT_LENGTH;

	if (!auth || !isValidToken(access) || !isValidToken(refresh)) {
		Swal.fire("Error", "No tienes acceso a esta zona! Intenta Iniciando Sesion.", "error");
		navigate("/");
	}

	return children;
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(PrivateRoute);
