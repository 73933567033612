import { useState, useEffect, useMemo } from "react";
import { Provider } from "react-redux"; // Importar Provider de react-redux
import store from "./store"; // Asegúrate de que la ruta al store sea correcta

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";


// Soft UI Dashboard PRO React example components
import Sidenav from "examples/Sidenav";
import Login from "./layouts/authentication/sign-in/cover";
import ResetPassword from "./layouts/authentication/reset-password/cover";
import ResetPasswordConfirm from "./layouts/authentication/reset-password-confirm/basic";
import ActivateAccount from "./layouts/authentication/activate-account/cover";

import JobNew from "./layouts/ecommerce/jobs/new-job";
import JobDetails from "./layouts/ecommerce/jobs/job-details";
import JobEdit from "./layouts/ecommerce/jobs/edit-job";

import BusinessProfile from "./layouts/pages/profile/profile-overview";

// Soft UI Dashboard PRO React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
// import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Soft UI Dashboard PRO React routes
import routes from "routes";

// Soft UI Dashboard PRO React contexts
import { useSoftUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
import brand from "assets/images/logo.png";
import Error404 from "layouts/authentication/error/404";
import NewProduct from "layouts/ecommerce/products/new-product";
import ProductDetails from "layouts/ecommerce/products/product-page";
import ProductEdit from "layouts/ecommerce/products/edit-product";
import UserEdit from "layouts/dashboards/users/edit-user";
import ProfileEdit from "layouts/pages/profile/edit-profile";
import AllJobs from "layouts/pages/profile/all-jobs";
import AllCompanies from "layouts/pages/profile/all-companies";
import PrivateRoutes from "PrivateRoutes";

export default function App() {
	const [controller, dispatch] = useSoftUIController();
	const { miniSidenav, direction, layout, openConfigurator, sidenavColor } = controller;
	const [onMouseEnter, setOnMouseEnter] = useState(false);
	const [rtlCache, setRtlCache] = useState(null);
	const { pathname } = useLocation();

	// Cache for the rtl
	useMemo(() => {
		const cacheRtl = createCache({
			key: "rtl",
			stylisPlugins: [rtlPlugin],
		});

		setRtlCache(cacheRtl);
	}, []);

	// Open sidenav when mouse enter on mini sidenav
	const handleOnMouseEnter = () => {
		if (miniSidenav && !onMouseEnter) {
			setMiniSidenav(dispatch, false);
			setOnMouseEnter(true);
		}
	};

	// Close sidenav when mouse leave mini sidenav
	const handleOnMouseLeave = () => {
		if (onMouseEnter) {
			setMiniSidenav(dispatch, true);
			setOnMouseEnter(false);
		}
	};

	// Setting the dir attribute for the body element
	useEffect(() => {
		document.body.setAttribute("dir", direction);
	}, [direction]);

	// Setting page scroll to 0 when changing the route
	useEffect(() => {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
	}, [pathname]);

	const getRoutes = (allRoutes) =>
		allRoutes.map((route) => {
			if (route.collapse) {
				return getRoutes(route.collapse);
			}

			if (route.route) {
				return <Route exact path={route.route} element={route.component} key={route.key} />;
			}

			return null;
		});

	return (
		<Provider store={store}>
			{" "}
			{/* Envolver con Provider */}
			<ThemeProvider theme={direction === "rtl" ? themeRTL : theme}>
				<CssBaseline />
				{layout === "dashboard" && (
					<>
						<Sidenav
							color={sidenavColor}
							brand={brand}
							brandName="Corpoindustrial Élite"
							routes={routes}
							onMouseEnter={handleOnMouseEnter}
							onMouseLeave={handleOnMouseLeave}
						/>
					</>
				)}
			
				<Routes>
					{getRoutes(routes)}

					<Route path="*" element={<Error404 />} />
					<Route path="/" element={<Login />} />
					<Route path="/reset-password" element={<ResetPassword />} />
					<Route path="/password/reset/confirm/:uid/:token" element={<ResetPasswordConfirm />} />
					<Route path="/active/:uid/:token" element={<ActivateAccount />} />

					<Route
						path="/product-new"
						element={
							<PrivateRoutes>
								<NewProduct />
							</PrivateRoutes>
						}
					/>
					<Route
						path="/product-details/:uid"
						element={
							<PrivateRoutes>
								<ProductDetails />
							</PrivateRoutes>
						}
					/>
					<Route
						path="/product-edit/:uid"
						element={
							<PrivateRoutes>
								<ProductEdit />
							</PrivateRoutes>
						}
					/>

					<Route
						path="/business-profile/:uid"
						element={
							<PrivateRoutes>
								<BusinessProfile />
							</PrivateRoutes>
						}
					/>

					<Route
						path="/job-new"
						element={
							<PrivateRoutes>
								<JobNew />
							</PrivateRoutes>
						}
					/>
					<Route
						path="/job-details/:uid"
						element={
							<PrivateRoutes>
								<JobDetails />
							</PrivateRoutes>
						}
					/>
					<Route
						path="/job-edit/:uid"
						element={
							<PrivateRoutes>
								<JobEdit />
							</PrivateRoutes>
						}
					/>
					<Route
						path="/dashboard/jobs"
						element={
							<PrivateRoutes>
								<AllJobs />
							</PrivateRoutes>
						}
					/>
					<Route
						path="/dashboard/companies"
						element={
							<PrivateRoutes>
								<AllCompanies />
							</PrivateRoutes>
						}
					/>

					<Route
						path="/user-edit/:id"
						element={
							<PrivateRoutes>
								<UserEdit />
							</PrivateRoutes>
						}
					/>

					<Route
						path="/profile-edit/:uid"
						element={
							<PrivateRoutes>
								<ProfileEdit />
							</PrivateRoutes>
						}
					/>
				</Routes>
			</ThemeProvider>
		</Provider>
	);
}
