import { combineReducers } from 'redux';
import auth from './auth.js';
import alert from './alert';
import users from './user.js';
import products from './products.js';
import jobs from './jobs.js';
import enterprise from './enterprise.js';
import dashboard from './dashboard.js';
export default combineReducers({
//  Autenticacion
    auth,
//  Alerta
    alert,
// usuarios
    users,
//	Productos
		products,	
//	Trabajos
		jobs,	
// profile enterprise
		enterprise,
// dashboard
	  dashboard,
})