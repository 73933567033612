// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// ProductPage page components
import JobImages from "layouts/ecommerce/jobs/job-details/components/JobImages";
import JobInfo from "layouts/ecommerce/jobs/job-details/components/JobInfo";


import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getJob } from "redux/actions/jobs/jobs";
import { connect, useDispatch } from "react-redux";

function JobPage({ getJob, job }) {
	const params = useParams();
	const id = params.uid;


	const dispatch = useDispatch();
	const [shouldLoadJob, setShouldLoadJob] = useState(true);
	useEffect(() => {
    if (shouldLoadJob) {
      dispatch({ type: 'GET_JOB_FAIL' }); // Resetea el perfil a null
      getJob(id); // Carga el perfil
      setShouldLoadJob(false); // Restablece el indicador
    }
  }, [id, shouldLoadJob, getJob, dispatch]);

	useEffect(() => {
		if (job === null) {
			getJob(id);
		}
	}, [id, getJob, job]);

	useEffect(() => {
		setShouldLoadJob(true);
	}
	, [id]);



	return (
		<DashboardLayout>
			<DashboardNavbar />
			{job && job ? (
				<SoftBox py={3}>
					<Card sx={{ overflow: "visible" }}>
						<SoftBox p={3}>
							<SoftBox mb={3}>
								<SoftTypography variant="h5" fontWeight="medium">
									Detalles de la oferta de trabajo
								</SoftTypography>
							</SoftBox>

							<Grid container spacing={3}>
								<Grid item xs={12} lg={6} xl={5}>
									<SoftBox borderRadius="xl" maxHeight="450px" overflow="hidden" display="flex" justifyContent="center" alignItems="center">
										<JobImages source={job.image} height="150%"/>
									</SoftBox>
								</Grid>
								<Grid item xs={12} lg={5} sx={{ mx: "auto" }}>
									<JobInfo
										title={job.title}
										priority={job.priority}
										description={job.description}
										enterprises={job.user}
										facebook={job.profile.facebook && job.profile.facebook ? job.profile.facebook : ""}
										X={job.profile.X && job.profile.X ? job.profile.X : ""}
										instagram={job.profile.instagram && job.profile.instagram ? job.profile.instagram : ""}
										address={job.profile.address && job.profile.address ? job.profile.address : ""}
									/>
								</Grid>
							</Grid>

							{/* <SoftBox mt={8} mb={2}>
								<SoftBox mb={1} ml={2}>
									<SoftTypography variant="h5" fontWeight="medium">
										Other Products
									</SoftTypography>
								</SoftBox>
								<DataTable
									table={dataTableData}
									entriesPerPage={false}
									showTotalEntries={false}
									isSorted={false}
								/>
							</SoftBox> */}
						</SoftBox>
					</Card>
				</SoftBox>
			) : (
				<SoftBox py={3}>
					<Card sx={{ overflow: "visible" }}>
						<SoftBox p={3}>
							<SoftBox mb={3}>
								<SoftTypography variant="h5" fontWeight="medium">
									No se ha encontrado la oferta de trabajo
								</SoftTypography>
							</SoftBox>
						</SoftBox>
					</Card>
				</SoftBox>
			)}
			<Footer />
		</DashboardLayout>
	);
}

const mapStateToProps = (state) => ({
	job: state.jobs.job,
});
export default connect(mapStateToProps, {
	getJob,
})(JobPage);
