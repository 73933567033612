import {
	GET_JOB_DASHBOARD_SUCCESS,
	GET_JOB_DASHBOARD_FAIL,
	GET_PRODUCT_DASHBOARD_FAIL,
	GET_PRODUCT_DASHBOARD_SUCCESS,
	GET_USER_DASHBOARD_FAIL,
	GET_USER_DASHBOARD_SUCCESS,
} from "../actions/dashboard/types";

const initialState = {
	user: null,
	product: null,
	job: null,
	count: null,
	next: null,
	previous: null,

};

export default function dashboard(state = initialState, action) {
	const { type, payload } = action;
	
	switch	(type) {
		case GET_USER_DASHBOARD_SUCCESS:
			return {
				...state,
				user: payload.results.users,
			};
		case GET_USER_DASHBOARD_FAIL:
			return {
				...state,
				user: null,
			};
		case GET_PRODUCT_DASHBOARD_SUCCESS:
			return {
				...state,
				product: payload.results.products,
			};
		case GET_PRODUCT_DASHBOARD_FAIL:
			return {
				...state,
				product: null,
			};
		case GET_JOB_DASHBOARD_SUCCESS:
			return {
				...state,
				job: payload.results.jobs,
			};
		case GET_JOB_DASHBOARD_FAIL:
			return {
				...state,
				job: null,
			};
		default:
			return state;
	}
}