const form = {
  formId: "new-services-form",
  formField: {
		name: {
			name: "name",
			label: "Titulo",
			type: "text",
			placeholder: "Ej. Ofrecemos servicios o productos de ...",
			errorMsg: "El campo nombre es requerido.",
		
		},
		description : {
			name : "description",
			label : "Descripción",
			type : "text",
			placeholder : "Ej. El servicio o producto consiste en ...",
			errorMsg : "El campo descripción es requerido.",
		},
		image : {
			name : "image",
			label : "Imagen",
			type : "file",
			placeholder : "Ej. Plan 1",
			errorMsg : "El campo imagen es requerido.",
		
		},

		category : {
			name : "category",
			label : "Categoria",
			type : "select",
			placeholder : "Ej. Plan 1",
			errorMsg : "El campo categoria es requerido.",
		
		},
		subcategory : {
			name : "subcategory",
			label : "Subcategoria",
			type : "select",
			placeholder : "Ej. Plan 1",
			errorMsg : "El campo subcategoria es requerido.",
		},
		extracategory : {
			name : "extracategory",
			label : "Extracategoria",
			type : "select",
			placeholder : "Ej. Plan 1",
			errorMsg : "El campo extracategoria es requerido.",
		},
  },
};

export default form;
