// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// EditProduct page components
import ProductImage from "layouts/ecommerce/products/edit-product/components/ProductImage";
import ProductInfo from "layouts/ecommerce/products/edit-product/components/ProductInfo";
// import Socials from "layouts/ecommerce/products/edit-product/components/Socials";
// import Pricing from "layouts/ecommerce/products/edit-product/components/Pricing";
import { useParams } from "react-router-dom";
import { getProduct } from "redux/actions/products/products";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import form from "../new-product/schemas/form";
import axios from "axios";
import Swal from "sweetalert2";

function EditProduct({ getProduct, product }) {
	const params = useParams();
	const id = params.uid;

	useEffect(() => {
		getProduct(id);
	}, [getProduct, id]);

	const [productData, setProductData] = useState({
		image: "",
		name: "",
		description: "",
		category: "",
		subcategory: "",
		extracategory: "",
		// Añade más campos según necesites
	});
	const handleProductDataChange = (field, value) => {
		setProductData((prevData) => ({
			...prevData,
			[field]: value,
		}));
	};

	const handleSave = () => {
		const config = {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };
		const formData = new FormData();
		formData.append("image", productData.image);
		formData.append("name", productData.name);
		formData.append("description", productData.description);
		formData.append("category", productData.category.value);
		formData.append("subcategory", productData.subcategory.value);
		formData.append("extracategory", productData.extracategory.value);
		formData.append("id", id);
		const fetchData = async () => {
      try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/product/edit/${id}/`,
				formData,
				config);
				
				if (res.status === 200) {
					Swal.fire("Beneficio editado", "El beneficio se ha editado correctamente", "success");
				}
				else {
					Swal.fire("Error", "Error al editar el beneficio", "error");
				}
			}
			catch (err) {
				Swal.fire("Error", "Error al editar el beneficio", "error");
			}
		}
		fetchData();


	};
	return (
		<DashboardLayout>
			<DashboardNavbar />
			{product && product ? (
				<SoftBox my={3}>
					<SoftBox mb={3}>
						<Grid container spacing={3} alignItems="center">
							<Grid item xs={12} lg={6}>
								<SoftTypography variant="h4" fontWeight="medium">
									Editar beneficio
								</SoftTypography>
								<SoftBox mt={1} mb={2}>
									<SoftTypography variant="body2" color="text">
										Realiza las modificaciones necesarias del beneficio que ofreces.
									</SoftTypography>
								</SoftBox>
							</Grid>
							<Grid item xs={12} lg={6}>
								<SoftBox display="flex" justifyContent="flex-end">
									<SoftButton variant="gradient" color="info" onClick={handleSave}>
										Guardar
									</SoftButton>
								</SoftBox>
							</Grid>
						</Grid>
					</SoftBox>
					<Grid container spacing={3}>
						<Grid item xs={12} lg={4}>
							<ProductImage image={product} setProductData={setProductData} />
						</Grid>
						<Grid item xs={12} lg={8}>
							<ProductInfo product={product} onProductChange={handleProductDataChange} />
						</Grid>
						<Grid item xs={12} lg={4}>
							{/* <Socials /> */}
						</Grid>
						<Grid item xs={12} lg={8}>
							{/* <Pricing /> */}
						</Grid>
					</Grid>
				</SoftBox>
			) : (
				// generame un skeleton para la carga
				<div>Loading...</div>
			)}
			<Footer />
		</DashboardLayout>
	);
}

const mapStateToProps = (state) => ({
	product: state.products.product,
});

export default connect(mapStateToProps, { getProduct })(EditProduct);
