import checkout from "layouts/dashboards/users/new-user/schemas/form";

const {
  formField: {
    firstName,
    lastName,
    document_type,
    nuip,
    email,
    password,
    repeatPassword,
   
  },
} = checkout;

const initialValues = {
  [firstName.name]: "",
  [lastName.name]: "",
  [document_type.name]: "",
  [nuip.name]: "",
  [email.name]: "",
  [password.name]: "",
  [repeatPassword.name]: "",
  
};

export default initialValues;
