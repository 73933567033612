// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";
import InstagramIcon from "@mui/icons-material/Instagram";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "layouts/pages/profile/profile-overview/components/ProfilesList";
import DefaultProjectCard from "layouts/pages/profile/profile-overview/components/DefaultProjectCard";
import PlaceholderCard from "layouts/pages/profile/profile-overview/components/PlaceholderCard";

// Overview page components
import Header from "layouts/pages/profile/components/Header";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { get_profile } from "redux/actions/enterprise/enterprise";
import { connect } from "react-redux";
import { jobsList } from "redux/actions/jobs/jobs";
import { productsList } from "redux/actions/products/products";
import { useSelector, useDispatch } from "react-redux";

function Overview({ get_profile, profile, jobsList, jobs, productsList, products }) {
	const params = useParams();
	const id = params.uid;
	const user = useSelector((state) => state.auth.user);
	const dispatch = useDispatch();
	const [shouldLoadProfile, setShouldLoadProfile] = useState(true);

	useEffect(() => {
		if (shouldLoadProfile) {
			dispatch({ type: "GET_PROFILE_FAIL" }); // Resetea el perfil a null
			get_profile(id); // Carga el perfil
			setShouldLoadProfile(false); // Restablece el indicador
		}
	}, [id, shouldLoadProfile, get_profile, dispatch]);

	// Este useEffect maneja la carga del perfil y la ejecución de jobsList y productsList
	useEffect(() => {
		if (profile === null) {
			get_profile(id);
		} else if (user && profile) {
			const userProfileId = profile.user?.id;
			const isEnterpriseUser = user.role === "enterprise";
			const isMatchingProfile = user.id === userProfileId;

			if (isEnterpriseUser && isMatchingProfile) {
				jobsList();
				productsList();
			} else if (isEnterpriseUser && !isMatchingProfile) {
				jobsList(userProfileId);
				productsList(userProfileId);
			} else {
				jobsList(userProfileId);
				productsList(userProfileId);
			}
		} else {
			console.log("Loading user and profile...");
		}
	}, [user, profile, jobsList, productsList]);

	useEffect(() => {
		setShouldLoadProfile(true);
	}, [id]);

	return (
		<>
			{profile ? (
				<DashboardLayout>
					<Header enterprise={profile} />
					<SoftBox mt={5} mb={3}>
						<Grid container spacing={3}>
							<Grid item xs={12} md={6} xl={8}>
								<ProfileInfoCard
									title="Información de la empresa"
									description={profile.description}
									info={{
										"Nombre completo": profile.user.enterprise,
										Teléfono: profile.phone,
										Dirección: profile.address,
									}}
									social={[
										{
											link: profile.facebook,
											icon: <FacebookIcon />,
											color: "facebook",
											key: "social-facebook",
										},
										{
											link: profile.instagram,
											icon: <InstagramIcon />,
											color: "instagram",
											key: "social-instagram",
										},
										{
											link: profile.X,
											icon: <XIcon />,
											color: "twitter",
											key: "social-x",
										},
									]}
									action={{ route: "", tooltip: "Edit Profile" }}
								/>
							</Grid>
							<Grid item xs={12} xl={4}>
								{products && products ? (
									<ProfilesList title="Beneficios" profiles={products} />
								) : (
									<PlaceholderCard title={{ variant: "h6", text: "Texto del título" }} />
								)}
							</Grid>
						</Grid>
					</SoftBox>
					<SoftBox mb={3}>
						<Card>
							<SoftBox pt={2} px={2}>
								<SoftBox mb={0.5}>
									<SoftTypography variant="h6" fontWeight="medium">
										Ofertas laborales
									</SoftTypography>
								</SoftBox>
								<SoftBox mb={1}>
									<SoftTypography fontWeight="regular" fontSize={"1.1rem"} color="text">
										Ofertas laborales disponibles en la empresa
									</SoftTypography>
								</SoftBox>
							</SoftBox>
							<SoftBox p={2}>
								<Grid container spacing={3}>
									{jobs &&
										jobs.map((job) => (
											<Grid item xs={12} md={6} xl={3} key={job.id}>
												<DefaultProjectCard
													id={job.id}
													image={job.image}
													label={job.title}
													title={job.title}
													description={job.description}
													action={{
														type: "internal",
														route: "/ruta-para-accion", 
														color: "primary",
														label: "Acción",
													}}
												/>
											</Grid>
										))}
								</Grid>
							</SoftBox>
						</Card>
					</SoftBox>
					<Footer />
				</DashboardLayout>
			) : (
				<DashboardLayout>
					<Header />
				</DashboardLayout>
			)}
		</>
	);
}

const mapStateToProps = (state) => ({
	profile: state.enterprise.enterprise,
	jobs: state.jobs.jobs,
	products: state.products.products,
});

export default connect(mapStateToProps, {
	get_profile,
	jobsList,
	productsList,
})(Overview);
