const form = {
	formId: "new-job-form",
	formField: {
		title: {
			name: "title",
			label: "Titulo",
			type: "text",
			placeholder: "Ej. Solicitamos personal",
			errorMsg: "El campo titulo es requerido.",
		},
		description: {
			name: "description",
			label: "Descripción",
			type: "text",
			placeholder: "Ej. Solicitamos personal para trabajar en ...",
			errorMsg: "El campo descripción es requerido.",
		},
		image: {
			name: "image",
			label: "Imagen",
			type: "file",
			placeholder: "Ej. Plan 1",
			errorMsg: "El campo imagen es requerido.",
		},
	},
};

export default form;
