import axios from "axios";
import {
	GET_USER_LIST_SUCCESS,
	GET_USER_LIST_FAIL,
	GET_USER_SUCCESS,
	GET_USER_FAIL,
	GET_USER_SEARCH_SUCCESS,
	GET_USER_SEARCH_FAIL,
	GET_PROFILE_SUCCESS,
	GET_PROFILE_FAIL,
} from "./types"


export const get_user_list = () => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/employee/list/`,
      config
    );
    if (res.status === 200) {
      dispatch({
        type: GET_USER_LIST_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_USER_LIST_FAIL,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_USER_LIST_FAIL,
    });
  }
};

// listar categoria por uuid
export const get_user = (id) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/employee/${id}/`,
      config
    );
    if (res.status === 200) {
      dispatch({
        type: GET_USER_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_USER_FAIL,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_USER_FAIL,
    });
  }
};

export const get_user_list_page = (p) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
			Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/employee/list/?p=${p}`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: GET_USER_LIST_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_USER_LIST_FAIL,
      });
    }
  } catch {
    dispatch({
      type: GET_USER_LIST_FAIL,
    });
  }
};


export const search_user = (search_term) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
    },
  };

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/employee/search/${search_term}`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: GET_USER_SEARCH_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_USER_SEARCH_FAIL,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_USER_SEARCH_FAIL,
    });
  }
};

export const get_profile = (id) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };
	try {
		const res = await axios.get(
			`${process.env.REACT_APP_API_URL}/enterprise/profile/${id}/`,
			config
		);
		if (res.status === 200) {
			dispatch({
				type: GET_PROFILE_SUCCESS,
				payload: res.data,
			});
		} else {
			dispatch({
				type: GET_PROFILE_FAIL,
			});
		}
	} catch (err) {
		dispatch({
			type: GET_PROFILE_FAIL,
		});
	}
}