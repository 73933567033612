import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { connect, useSelector } from "react-redux";
import { get_user } from "redux/actions/user/user";
import axios from "axios";

import Toast from "components/Alert";

import estados from "components/territorio/estado";
import municipios from "components/territorio/municipios";
import { deparments } from "components/laboral/choices";
import { roles } from "components/laboral/choices";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { Card, Grid, MenuItem, Select } from "@mui/material";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import Swal from "sweetalert2";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
function UserEdit({ get_user, user }) {
	const params = useParams();
	const id = params.id;

	const useradmin = useSelector((state) => state.auth.user);
	const [updateDocumentType, setUpdateDocumentType] = useState(false);
	const [updateFirstName, setUpdateFirstName] = useState(false);
	const [updateLastName, setUpdateLastName] = useState(false);
	const [updateGender, setUpdateGender] = useState(false);
	const [updateNuip, setUpdateNuip] = useState(false);
	const [updateEmail, setUpdateEmail] = useState(false);
	const [updateUsername, setUpdateUsername] = useState(false);

	const [gender, setGender] = useState("...");
	const handleSetGender = (event) => setGender(event.target.value);

	useEffect(() => {
		get_user(id);
	}, [get_user, id]);

	const [formData, setFormData] = useState({
		email: "",
		username: "",
		first_name: "",
		last_name: "",
		document_type: "",
		nuip: "",
		gender: "",
	});
	const { email, username, first_name, last_name, document_type, nuip, role } = formData;
	const resetStates = () => {
		setUpdateDocumentType(false);
		setUpdateFirstName(false);
		setUpdateLastName(false);
		setUpdateGender(false);
		setUpdateNuip(false);
		setUpdateEmail(false);
		setUpdateUsername(false);
	};
	const onChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const [loading, setLoading] = useState(false);

	const onSubmit = (e) => {
		e.preventDefault();
		const config = {
			headers: {
				Accept: "application/json",
				"Content-Type": "multipart/form-data",
				Authorization: `JWT ${localStorage.getItem("access")}`,
			},
		};
		const formData = new FormData();
		formData.append("document_type", document_type);
		formData.append("first_name", first_name);
		formData.append("last_name", last_name);
		formData.append("gender", gender);
		formData.append("nuip", nuip);
		formData.append("email", email);
		formData.append("username", username);
		formData.append("id", id);

		const fetchData = async () => {
			setLoading(true);
			try {
				const res = await axios.put(
					`${process.env.REACT_APP_API_URL}/employee/edit/${id}/`,
					formData,
					config
				);
				if (res.status === 200) {
					setFormData({
						document_type: "",
						first_name: "",
						last_name: "",
						gender: "",
						nuip: "",
						email: "",
						username: "",
						phone: "",
						state: "",
						municipality: "",
					});
					resetStates();
					get_user(id);
					Swal.fire("Usuario editado exitosamente", "", "success");
				} else {
					setLoading(false);
					resetStates();
					Swal.fire("Error al editar el usuario", "", "error");
				}
			} catch (err) {
				setLoading(false);
				resetStates();
				Swal.fire("Error al editar el usuario", "", "error");
			}
		};
		fetchData();
	};
	return (
		<DashboardLayout>
			<DashboardNavbar />
			{user ? (
				<>
					<SoftBox mt={8}>
						<Grid container spacing={3} justifyContent="center">
							<Grid item xs={12} lg={8} mb={2}>
								<SoftBox display="flex" alignItems="center">
									<SoftTypography variant="h5" fontWeight="bold">
										Editar usuario: {user && user.first_name} {user && user.last_name}
									</SoftTypography>
								</SoftBox>
							</Grid>
						</Grid>
					</SoftBox>
					<SoftBox>
						<Grid container spacing={3} justifyContent="center">
							<Grid item xs={12} lg={8} mb={2}>
								<Card sx={{ height: "100%", padding: "1rem" }}>
									<SoftTypography variant="h5" fontWeight="bold">
										Información del Usuario
									</SoftTypography>

									<SoftBox p={2}>
										<SoftBox mt={1} width="100%" display="flex" justifyContent="space-between">
											<Grid container spacing={3}>
												<Grid item xs={12} sm={6}>
													<SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
														<SoftTypography
															component="label"
															variant="caption"
															fontWeight="bold"
															textTransform="capitalize"
															fontSize="0.9rem"
														>
															Cédula
														</SoftTypography>
														{updateNuip ? (
															<form onSubmit={(e) => onSubmit(e)}>
																<SoftBox mt={1}>
																	<SoftInput
																		value={nuip}
																		onChange={(e) => onChange(e)}
																		name="nuip"
																		type="text"
																		fullWidth
																		inputProps={{
																			pattern: "[0-9]+",
																			minLength: 6,
																			maxLength: 11,
																			required: true,
																		}}
																	/>
																</SoftBox>
																<SoftBox display="flex" gap={2} mt={1}>
																	<SoftButton
																		type="submit"
																		variant="outlined"
																		color="success"
																		size="small"
																	>
																		Guardar
																	</SoftButton>
																	<SoftButton
																		onClick={() => setUpdateNuip(false)}
																		variant="outlined"
																		color="success"
																		size="small"
																	>
																		Cancelar
																	</SoftButton>
																</SoftBox>
															</form>
														) : (
															<>
																<SoftBox
																	py={1}
																	bgcolor="grey.100"
																	borderRadius="lg"
																	fullWidth
																>
																	<SoftTypography fontWeight="light" fontSize={"1.1rem"}>{user.nuip}</SoftTypography>
																</SoftBox>
																<SoftBox mt={1}>
																	<SoftButton
																		onClick={() => setUpdateNuip(true)}
																		variant="outlined"
																		color="success"
																		size="small"
																	>
																		Modificar
																	</SoftButton>
																</SoftBox>
															</>
														)}
													</SoftBox>
												</Grid>
												<Grid item xs={12} sm={6}>
													<SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
														<SoftTypography
															component="label"
															variant="caption"
															fontWeight="bold"
															textTransform="capitalize"
															fontSize="0.9rem"
														>
															Nombres
														</SoftTypography>
														{updateFirstName ? (
															<form onSubmit={(e) => onSubmit(e)}>
																<SoftBox mt={1}>
																	<SoftInput
																		value={first_name}
																		onChange={(e) => onChange(e)}
																		name="first_name"
																		type="text"
																		fullWidth
																		inputProps={{
																			required: true,
																		}}
																	/>
																</SoftBox>
																<SoftBox display="flex" gap={2} mt={1}>
																	<SoftButton
																		type="submit"
																		variant="outlined"
																		color="success"
																		size="small"
																	>
																		Guardar
																	</SoftButton>
																	<SoftButton
																		onClick={() => setUpdateFirstName(false)}
																		variant="outlined"
																		color="success"
																		size="small"
																	>
																		Cancelar
																	</SoftButton>
																</SoftBox>
															</form>
														) : (
															<>
																<SoftBox
																	py={1}
																	bgcolor="grey.100"
																	borderRadius="lg"
																	fullWidth
																>
																	<SoftTypography fontWeight="light" fontSize={"1.1rem"}>{user.first_name}</SoftTypography>
																</SoftBox>
																<SoftBox mt={1}>
																	<SoftButton
																		onClick={() => setUpdateFirstName(true)}
																		variant="outlined"
																		color="success"
																		size="small"
																	>
																		Modificar
																	</SoftButton>
																</SoftBox>
															</>
														)}
													</SoftBox>
												</Grid>
												<Grid item xs={12} sm={6}>
													<SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
														<SoftTypography
															component="label"
															variant="caption"
															fontWeight="bold"
															textTransform="capitalize"
															fontSize="0.9rem"
														>
															Apellidos
														</SoftTypography>
														{updateLastName ? (
															<form onSubmit={(e) => onSubmit(e)}>
																<SoftBox mt={1}>
																	<SoftInput
																		value={last_name}
																		onChange={(e) => onChange(e)}
																		name="last_name"
																		type="text"
																		fullWidth
																		inputProps={{
																			required: true,
																		}}
																	/>
																</SoftBox>
																<SoftBox display="flex" gap={2} mt={1}>
																	<SoftButton
																		type="submit"
																		variant="outlined"
																		color="success"
																		size="small"
																	>
																		Guardar
																	</SoftButton>
																	<SoftButton
																		onClick={() => setUpdateLastName(false)}
																		variant="outlined"
																		color="success"
																		size="small"
																	>
																		Cancelar
																	</SoftButton>
																</SoftBox>
															</form>
														) : (
															<>
																<SoftBox
																	py={1}
																	bgcolor="grey.100"
																	borderRadius="lg"
																	fullWidth
																>
																	<SoftTypography fontWeight="light" fontSize={"1.1rem"}>{user.last_name}</SoftTypography>
																</SoftBox>
																<SoftBox mt={1}>
																	<SoftButton
																		onClick={() => setUpdateLastName(true)}
																		variant="outlined"
																		color="success"
																		size="small"
																	>
																		Modificar
																	</SoftButton>
																</SoftBox>
															</>
														)}
													</SoftBox>
												</Grid>
												<Grid item xs={12} sm={6}>
													<SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
														<SoftTypography
															component="label"
															variant="caption"
															fontWeight="bold"
															textTransform="capitalize"
															fontSize="0.9rem"
														>
															Género
														</SoftTypography>
														{updateGender ? (
															<form onSubmit={(e) => onSubmit(e)}>
																<SoftBox mt={1}>
																	<Select
																		input={<SoftInput />}
																		value={gender}
																		onChange={handleSetGender}
																	>
																		<MenuItem value="...">Selecciona una opción</MenuItem>
																		<MenuItem value="Masculino">Masculino</MenuItem>
																		<MenuItem value="Femenino">Femenino</MenuItem>
																		<MenuItem value="Otro">Otro</MenuItem>
																	</Select>
																</SoftBox>
																<SoftBox display="flex" gap={2} mt={1}>
																	<SoftButton
																		type="submit"
																		variant="outlined"
																		color="success"
																		size="small"
																	>
																		Guardar
																	</SoftButton>
																	<SoftButton
																		onClick={() => setUpdateGender(false)}
																		variant="outlined"
																		color="success"
																		size="small"
																	>
																		Cancelar
																	</SoftButton>
																</SoftBox>
															</form>
														) : (
															<>
																<SoftBox
																	py={1}
																	bgcolor="grey.100"
																	borderRadius="lg"
																	fullWidth
																>
																	<SoftTypography fontWeight="light" fontSize={"1.1rem"}>{user.gender}</SoftTypography>
																</SoftBox>
																<SoftBox mt={1}>
																	<SoftButton
																		onClick={() => setUpdateGender(true)}
																		variant="outlined"
																		color="success"
																		size="small"
																	>
																		Modificar
																	</SoftButton>
																</SoftBox>
															</>
														)}
													</SoftBox>
												</Grid>
											</Grid>
										</SoftBox>
									</SoftBox>
								</Card>
							</Grid>
						</Grid>
					</SoftBox>

					<SoftBox>
						<Grid container spacing={3} justifyContent="center">
							<Grid item xs={12} lg={8}>
								<Card sx={{ height: "100%", padding: "1rem" }}>
									<SoftTypography variant="h5" fontWeight="bold">
										Información de Contacto
									</SoftTypography>

									<SoftBox p={2}>
										<SoftBox mt={1} width="100%" display="flex" justifyContent="space-between">
											<Grid container spacing={3}>
												<Grid item xs={12} sm={6}>
													<SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
														<SoftTypography
															component="label"
															variant="caption"
															fontWeight="bold"
															textTransform="capitalize"
															fontSize="0.9rem"
														>
															Correo Electrónico
														</SoftTypography>
														{updateEmail ? (
															<form onSubmit={(e) => onSubmit(e)}>
																<SoftBox mt={1}>
																	<SoftInput
																		value={email}
																		onChange={(e) => onChange(e)}
																		name="email"
																		type="email"
																		fullWidth
																		inputProps={{
																			required: true,
																		}}
																	/>
																</SoftBox>
																<SoftBox display="flex" gap={2} mt={1}>
																	<SoftButton
																		type="submit"
																		variant="outlined"
																		color="success"
																		size="small"
																	>
																		Guardar
																	</SoftButton>
																	<SoftButton
																		onClick={() => setUpdateEmail(false)}
																		variant="outlined"
																		color="success"
																		size="small"
																	>
																		Cancelar
																	</SoftButton>
																</SoftBox>
															</form>
														) : (
															<>
																<SoftBox
																	py={1}
																	bgcolor="grey.100"
																	borderRadius="lg"
																	fullWidth
																>
																	<SoftTypography fontWeight="light" fontSize={"1.1rem"}>{user.email}</SoftTypography>
																</SoftBox>
																<SoftBox mt={1}>
																	<SoftButton
																		onClick={() => setUpdateEmail(true)}
																		variant="outlined"
																		color="success"
																		size="small"
																	>
																		Modificar
																	</SoftButton>
																</SoftBox>
															</>
														)}
													</SoftBox>
												</Grid>
												<Grid item xs={12} sm={6}>
													<SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
														<SoftTypography
															component="label"
															variant="caption"
															fontWeight="bold"
															textTransform="capitalize"
															fontSize="0.9rem"
														>
															Nombre de Usuario
														</SoftTypography>
														{updateUsername ? (
															<form onSubmit={(e) => onSubmit(e)}>
																<SoftBox mt={1}>
																	<SoftInput
																		value={username}
																		onChange={(e) => onChange(e)}
																		name="username"
																		type="text"
																		fullWidth
																		inputProps={{
																			required: true,
																		}}
																	/>
																</SoftBox>
																<SoftBox display="flex" gap={2} mt={1}>
																	<SoftButton
																		type="submit"
																		variant="outlined"
																		color="success"
																		size="small"
																	>
																		Guardar
																	</SoftButton>
																	<SoftButton
																		onClick={() => setUpdateUsername(false)}
																		variant="outlined"
																		color="success"
																		size="small"
																	>
																		Cancelar
																	</SoftButton>
																</SoftBox>
															</form>
														) : (
															<>
																<SoftBox
																	py={1}
																	bgcolor="grey.100"
																	borderRadius="lg"
																	fullWidth
																>
																	<SoftTypography fontWeight="light" fontSize={"1.1rem"}>{user.username}</SoftTypography>
																</SoftBox>
																<SoftBox mt={1}>
																	<SoftButton
																		onClick={() => setUpdateUsername(true)}
																		variant="outlined"
																		color="success"
																		size="small"
																	>
																		Modificar
																	</SoftButton>
																</SoftBox>
															</>
														)}
													</SoftBox>
												</Grid>
											</Grid>
										</SoftBox>
									</SoftBox>
								</Card>
							</Grid>
						</Grid>
					</SoftBox>
				</>
			) : (
				<div className="w-full h-full flex justify-center items-center">{"No se encontró el usuario"}</div>
			)}
		</DashboardLayout>
	);
}

const mapStateToProps = (state) => ({
	user: state.users.user,
});
export default connect(mapStateToProps, {
	get_user,
})(UserEdit);
