// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Editjob page components
import JobImage from "layouts/ecommerce/jobs/edit-job/components/JobImage";
import JobInfo from "layouts/ecommerce/jobs/edit-job/components/JobInfo";
// import Socials from "layouts/ecommerce/jobs/edit-job/components/Socials";
// import Pricing from "layouts/ecommerce/jobs/edit-job/components/Pricing";

import { connect } from "react-redux";
import { getJob } from "redux/actions/jobs/jobs";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
function EditJob({ getJob, job }) {
	const params = useParams();
	const id = params.uid;

	useEffect(() => {
		getJob(id);
	}, [getJob, id]);

	const [jobData, setJobData] = useState({
		image: "",
		title: "",
		description: "",
		priority: "",
		status: "",
		// Añade más campos según necesites
	});
	const handleJobDataChange = (field, value) => {
		setJobData((prevData) => ({
			...prevData,
			[field]: value,
		}));
	};
	console.log(jobData);
	const handleSave = () => {
		const config = {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };
		const formData = new FormData();
		formData.append("image", jobData.image);
		formData.append("title", jobData.title);
		formData.append("description", jobData.description);
		formData.append("priority", jobData.priority.value);
		formData.append("status", jobData.status);
		formData.append("id", id);
		const fetchData = async () => {
      try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/job/edit/${id}/`,
				formData,
				config);
				
				if (res.status === 200) {
					Swal.fire("Empleo editado", "El empleo se ha editado correctamente", "success");
				}
				else {
					Swal.fire("Error", "Ha ocurrido un error", "error");
				}
			}
			catch (err) {
				Swal.fire("Error", "Ha ocurrido un error", "error");
			}
		}
		fetchData();
	}


	return (
		<DashboardLayout>
			<DashboardNavbar />
			{
				job && job ? (
					<SoftBox my={3}>
				<SoftBox mb={3}>
					<Grid container spacing={3} alignItems="center">
						<Grid item xs={12} lg={6}>
							<SoftTypography variant="h4" fontWeight="medium">
								Editar oferta de trabajo
							</SoftTypography>
							<SoftBox mt={1} mb={2}>
								<SoftTypography variant="body2" color="text">
									Realiza las modificaciones necesarias en la oferta laboral y guarda los cambios.
								</SoftTypography>
							</SoftBox>
						</Grid>
						<Grid item xs={12} lg={6}>
							<SoftBox display="flex" justifyContent="flex-end">
							<SoftButton variant="gradient" color="info" onClick={handleSave}>
										Guardar
									</SoftButton>
							</SoftBox>
						</Grid>
					</Grid>
				</SoftBox>
				<Grid container spacing={3}>
					<Grid item xs={12} lg={4}>
						<JobImage image={job.image} setJobData={setJobData}/>
					</Grid>
					<Grid item xs={12} lg={8}>
						<JobInfo job={job} onJobChange={handleJobDataChange}/>
					</Grid>
					<Grid item xs={12} lg={4}>
						{/* <Socials /> */}
					</Grid>
					<Grid item xs={12} lg={8}>
						{/* <Pricing /> */}
					</Grid>
				</Grid>
			</SoftBox>
				) :(
					<SoftBox>
						<SoftTypography variant="h4" fontWeight="medium">
							No se encontró el trabajo
						</SoftTypography>
					</SoftBox>
				
				)
			}
			<Footer />
		</DashboardLayout>
	);
}

const mapStateToProps = (state) => ({
	job: state.jobs.job,
});

export default connect(mapStateToProps, { getJob })(EditJob);
