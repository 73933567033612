// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// NewUser page components
import FormField from "layouts/ecommerce/jobs/new-job/components/FormField";
import SoftSelect from "components/SoftSelect";

function JobInfo({ formData }) {
	const { formField, values, errors, touched, setFieldValue } = formData;
	const { title, description } = formField;
	const { title: titleV, description: descriptionV } = values;

	const selectData = {
		priority: [
			{ value: "Baja", label: "Baja" },
			{ value: "Media", label: "Media" },
			{ value: "Alta", label: "Alta" },
		],
		
	};
	return (
		<SoftBox>
			<SoftBox lineHeight={0}>
				<SoftTypography variant="h5" fontWeight="bold">
					Información del Empleo
				</SoftTypography>
			</SoftBox>
			<SoftBox mt={1.625}>
				<Grid container spacing={3}>

					<Grid item xs={12} sm={6}>
						<FormField
							type={title.type}
							label={title.label}
							name={title.name}
							value={titleV}
							placeholder={title.placeholder}
							error={errors.title && touched.title}
							success={titleV.length > 0 && !errors.title}
							inputProps={{ autoComplete: "" }}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<SoftTypography
							component="label"
							variant="caption"
							fontWeight="bold"
							textTransform="capitalize"
						>
							Prioridad
						</SoftTypography>
						<SoftSelect
							placeholder="Selecciona una opcion"
							options={selectData.priority}
							onChange={(value) => setFieldValue("priority", value)}
						/>
					</Grid>
					<Grid item xs={12} sm={12}>
						<FormField
							multiline
							rows={5}
							type={description.type}
							label={description.label}
							name={description.name}
							value={descriptionV}
							placeholder={description.placeholder}
							error={errors.description && touched.description}
							success={descriptionV.length > 0 && !errors.description}
							inputProps={{ autoComplete: "" }}
						/>
					</Grid>
				</Grid>
			</SoftBox>
		</SoftBox>
	);
}

// typechecking props for JobInfo
JobInfo.propTypes = {
	formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default JobInfo;
