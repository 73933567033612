import {
	GET_ENTERPRISE_SUCCESS,
	GET_ENTERPRISE_FAIL
} from "../actions/enterprise/types"

const initialState = {
	enterprise: null,
	count: null,
	next: null,
	previous: null,
};

export default function enterprise(state = initialState, action) {
	const { type, payload } = action;
	
	switch	(type) {
		case GET_ENTERPRISE_SUCCESS:
			return {
				...state,
				enterprise: payload.enterprise,
			};
		case GET_ENTERPRISE_FAIL:
			return {
				...state,
				enterprise: null,
			};
		default:
			return state;
	}
};