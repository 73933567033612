// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";
import { Divider } from "@mui/material";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";
import InstagramIcon from "@mui/icons-material/Instagram";
import typography from "assets/theme/base/typography";
import colors from "assets/theme/base/colors";


function JobInfo(job) {
	const { enterprises } = job;
	const user = useSelector((state) => state.auth.user);
	const social = [
		{
			link: job.facebook,
			icon: <FacebookIcon />,
			color: "facebook",
			key: "social-facebook",
		},
		{
			link: job.instagram,
			icon: <InstagramIcon />,
			color: "instagram",
			key: "social-instagram",
		},
		{
			link: job.X,
			icon: <XIcon />,
			color: "twitter",
			key: "social-x",
		},
	];
	const { size } = typography;
	const { socialMediaColors } = colors;

	return (
		<SoftBox>
			<SoftBox mb={1}>
				<SoftTypography variant="h3" fontWeight="bold">
					{job.title}
				</SoftTypography>
			</SoftBox>
			<SoftBox mt={1} mb={1} display={"flex"}>
				<SoftTypography fontSize={16}>Prioridad:</SoftTypography>
				<SoftTypography variant={"h4"}>
					{job.priority === "Alta" ? (
						<>
							<Icon>star</Icon>
							<Icon>star</Icon>
							<Icon>star</Icon>
						</>
					) : job.priority === "Media" ? (
						<>
							<Icon>star</Icon>
							<Icon>star</Icon>
							<Icon>star_outline</Icon>
						</>
					) : (
						<>
							<Icon>star</Icon>
							<Icon>star_outline</Icon>
							<Icon>star_outline</Icon>
						</>
					)}
				</SoftTypography>
			</SoftBox>
			<SoftBox mt={1} mb={1}>
				<SoftTypography fontWeight="bold">Descripción</SoftTypography>
			</SoftBox>
			<SoftBox m={0} mb={2} fontSize={16}>
				{job.description}
			</SoftBox>
			{user && user.id === enterprises ? (
				<>
					<Divider />

					<SoftBox m={0} mb={2} fontSize={14}>
						Creado: {dayjs(job.created).format("DD/MM/YYYY - hh:mm A")}
					</SoftBox>
				</>
			) : (
				<>
					<Divider />
					<SoftBox mb={1}>
						<SoftTypography variant="h5" fontWeight="bold">
							Empresa: {enterprises.enterprise}
						</SoftTypography>
					</SoftBox>
					<SoftBox mb={1}>
						<SoftTypography fontSize={16}>Redes sociales: {social.map(({ link, icon, color }) => (
								<SoftBox
									key={color}
									component="a"
									href={link}
									target="_blank"
									rel="noreferrer"
									fontSize={size.lg}
									color={socialMediaColors[color].main}
									pr={1}
									pl={0.5}
									lineHeight={1}
								>
									{icon}
								</SoftBox>
							))}</SoftTypography>
					</SoftBox>
					<SoftBox mb={1}>
						<SoftTypography fontSize={16}>Dirección: {job.address}</SoftTypography>
					</SoftBox>
					<Divider />
					<SoftBox m={0} mb={2} fontSize={14}>
						Creado: {dayjs(job.created).format("DD/MM/YYYY - hh:mm A")}
					</SoftBox>
				</>
			)}
			{/* <SoftBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={5}>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Frame Material
              </SoftTypography>
            </SoftBox>
            <SoftSelect defaultValue={frameOptions[3]} options={frameOptions} />
          </Grid>
          <Grid item xs={12} lg={5}>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Color
              </SoftTypography>
            </SoftBox>
            <SoftSelect defaultValue={colorOptions[5]} options={colorOptions} />
          </Grid>
          <Grid item xs={12} lg={2}>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Quantity
              </SoftTypography>
            </SoftBox>
            <SoftInput inputProps={{ type: "number" }} defaultValue={1} />
          </Grid>
        </Grid>
      </SoftBox> */}
			{/* <SoftBox mt={3}>
        <Grid item xs={12} lg={5} container>
          <SoftButton variant="gradient" color="info" fullWidth>
            add to cart
          </SoftButton>
        </Grid>
      </SoftBox> */}
		</SoftBox>
	);
}

export default JobInfo;
