// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import PageLayout from "examples/LayoutContainers/PageLayout";

// Soft UI Dashboard PRO React base styles
import typography from "assets/theme/base/typography";

// Authentication layout components
import Footer from "layouts/authentication/components/Footer";

// Soft UI Dashboard PRO React page layout routes
// import pageRoutes from "page.routes";

// Images
import error404 from "assets/images/illustrations/error-404.png";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";


function Error404() {
	const auth = JSON.parse(localStorage.getItem("auth"));

	const { d1, d3, d4, d5 } = typography;

	return auth ? (
		<DashboardLayout>
			{/* <DashboardNavbar /> */}
			<SoftBox my={5} height="calc(100vh - 24rem)">
				<Grid
					container
					spacing={3}
					justifyContent="center"
					alignItems="center"
					sx={{ height: "100%" }}
				>
					<Grid item xs={11} sm={9} container alignItems="center">
						<Grid item xs={12} lg={6}>
							<SoftBox
								fontSize={{ xs: d5.fontSize, sm: d4.fontSize, md: d3.fontSize, lg: d1.fontSize }}
								lineHeight={1.2}
							>
								<SoftTypography variant="inherit" color="error" textGradient fontWeight="bold">
									Error 404
								</SoftTypography>
							</SoftBox>
							<SoftTypography variant="h2" color="dark" textGradient fontWeight="bold">
								Página no encontrada
							</SoftTypography>
							<SoftBox mt={1} mb={2}>
								<SoftTypography variant="body1" color="text">
									Le sugerimos que vaya a la página de inicio mientras resolvemos este problema.
								</SoftTypography>
							</SoftBox>
							<SoftBox mt={4} mb={2}>
								<SoftButton component={Link} to="/dashboard/benefits" variant="gradient" color="dark">
									Ir a la página de inicio
								</SoftButton>
							</SoftBox>
						</Grid>
						<Grid item xs={12} lg={6}>
							<SoftBox component="img" src={error404} alt="error-404" width="100%" />
						</Grid>
					</Grid>
				</Grid>
			</SoftBox>
			<Footer />
		</DashboardLayout>
	) : (
		<PageLayout white>
			{/* <DefaultNavbar
				routes={pageRoutes}
				transparent
				action={{
					type: "external",
					route: "https://creative-tim.com/product/soft-ui-dashboard-pro-react",
					label: "buy now",
					color: "dark",
				}}
			/> */}
			<SoftBox my={16} height="calc(100vh - 24rem)">
				<Grid
					container
					spacing={3}
					justifyContent="center"
					alignItems="center"
					sx={{ height: "100%" }}
				>
					<Grid item xs={11} sm={9} container alignItems="center">
						<Grid item xs={12} lg={6}>
							<SoftBox
								fontSize={{ xs: d5.fontSize, sm: d4.fontSize, md: d3.fontSize, lg: d1.fontSize }}
								lineHeight={1.2}
							>
								<SoftTypography variant="inherit" color="error" textGradient fontWeight="bold">
									Error 404
								</SoftTypography>
							</SoftBox>
							<SoftTypography variant="h2" color="success" textGradient fontWeight="bold">
								Página no encontrada
							</SoftTypography>
							<SoftBox mt={1} mb={2}>
								<SoftTypography variant="body1" color="text">
									Le sugerimos que vaya a la página de inicio mientras resolvemos este problema.
								</SoftTypography>
							</SoftBox>
							<SoftBox mt={4} mb={2}>
								<SoftButton component={Link} to="/" variant="gradient" color="success">
									Ir a la página de inicio
								</SoftButton>
							</SoftBox>
						</Grid>
						<Grid item xs={12} lg={6}>
							<SoftBox component="img" src={error404} alt="error-404" width="100%" />
						</Grid>
					</Grid>
				</Grid>
			</SoftBox>
			<Footer />
		</PageLayout>
	);
}

export default Error404;
