import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
// import Menu from "@mui/material/Menu";
// import MenuItem from "@mui/material/MenuItem";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
// import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import TeamProfileCard from "examples/Cards/TeamCards/TeamProfileCard";
// import EventCard from "examples/Cards/EventCard";

// Teams page components
import Header from "layouts/pages/profile/components/Header";
import Stories from "layouts/pages/profile/all-companies/components/Stories";
import Post from "layouts/pages/profile/all-companies/components/Post";
import Pagination from "@mui/material/Pagination";
import Card from "@mui/material/Card";

import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
	get_user_dashboard,
	get_product_dashboard,
	get_job_dashboard,
} from "redux/actions/dashboard/dashboard";
import { connect } from "react-redux";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";

function AllCompanies({
	get_user_dashboard,
	users,
	get_product_dashboard,
	products,
	get_job_dashboard,
	jobs,
}) {
	const user = useSelector((state) => state.auth.user);

	useEffect(() => {
		get_user_dashboard();
		get_product_dashboard();
		get_job_dashboard();
	}, [get_user_dashboard, get_product_dashboard, get_job_dashboard]);

	return (
		<DashboardLayout>
			<Header user={user} />
			<SoftBox my={3}>
				<Card>
					<SoftBox p={2}>
						<SoftTypography variant="h6" fontWeight="medium" textGradient>
							Empresas
						</SoftTypography>
					</SoftBox>
				</Card>
			</SoftBox>
			<SoftBox mb={3}>
				<Grid container spacing={3}>
					<Grid item xs={12} lg={12} order={{xs: 1, lg: 2}}>
						<Stack direction={{xs: 'row', lg: 'column'}}>
							<Grid container spacing={3}>
								{users && users.map((user, index) => (
									<Grid item xs={12} md={6} lg={4} key={index}>
										<Card>
											<Link to={`/business-profile/${user.user.id}`}>
												<SoftBox p={2}>
													<SoftBox display="flex" alignItems="center" sx={{gap: 2}}>
														<SoftAvatar src={user.user.picture} alt="profile-image" variant="rounded" sx={{width: "80px", height: "80px"}} />
														<SoftBox>
															<SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize" textGradient>
																{user.user.enterprise}
															</SoftTypography>
															<SoftTypography variant="body2" color="text" fontWeight="regular">
																{user.user.description}
															</SoftTypography>
														</SoftBox>
													</SoftBox>
												</SoftBox>
											</Link>
										</Card>
									</Grid>
								))}
							</Grid>
						</Stack>
					</Grid>
				</Grid>
			</SoftBox>
			<SoftBox display="flex" justifyContent="center" my={2}>
				<Pagination count={10} color="success" />
			</SoftBox>
			<Footer />
		</DashboardLayout>
	);
}

const mapStateToProps = (state) => ({
	users: state.dashboard.user,
	products: state.dashboard.product,
	jobs: state.dashboard.job,
});

export default connect(mapStateToProps, {
	get_user_dashboard,
	get_product_dashboard,
	get_job_dashboard,
})(AllCompanies);
