import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftSelect from "components/SoftSelect";
import SoftDropzone from "components/SoftDropzone";

// NewProduct page components
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";

function ProfileInfo({ profile, onProfileChange }) {

	const handleChange = (eventOrValue, fieldName) => {
		// Verifica si el argumento es un evento (tiene target) o un valor directo
		const value = eventOrValue.target ? eventOrValue.target.value : eventOrValue;
		onProfileChange(fieldName, value);
	};
	return (
		<Card sx={{ overflow: "visible" }}>
			<SoftBox p={3}>
				<SoftTypography variant="h5">Información de la empresa</SoftTypography>
				<SoftBox mt={1}>
					<Grid container spacing={3}>
						{/* <Grid item xs={12} sm={6}>
							<FormField type="text" label="nombre" defaultValue={profile} />
						</Grid> */}
						<Grid item xs={12} sm={3}>
							<SoftBox mb={3}>
								<SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
									<SoftTypography
										component="label"
										variant="caption"
										fontWeight="bold"
										textTransform="capitalize"
									>
										Tipo de documento
									</SoftTypography>
								</SoftBox>
								<SoftSelect
									defaultValue={{
										value: profile.document_type_enterprise === "NIT" ? "NIT" : "Cedula de ciudadania",
										label: profile.document_type_enterprise === "NIT" ? "NIT" : "Cedula de ciudadania",
									}}
									options={[
										{ value: "NIT", label: "NIT" },
										{ value: "CC", label: "Cédula de ciudadanía" },
									]}
									onChange={(e) => handleChange(e, "document_type_enterprise")}
								/>
							</SoftBox>
						</Grid>
						<Grid item xs={12} sm={3}>
							<FormField
								type="number"
								label="nuip"
								defaultValue={profile.nuip_enterprise}
								onChange={(e) => handleChange(e, "nuip_enterprise")}
							/>
						</Grid>
					</Grid>
				</SoftBox>
				<SoftBox mt={1}>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={3}>
							<FormField
								type="text"
								label="teléfono"
								defaultValue={profile.phone}
								onChange={(e) => handleChange(e, "phone")}
							/>
						</Grid>
						<Grid item xs={12} sm={3}>
							{/* <FormField type="text" label="price" defaultValue="$90" /> */}
						</Grid>
					</Grid>
				</SoftBox>
				<SoftBox mt={1}>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={6}>
							<SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
								<SoftTypography component="label" variant="caption" fontWeight="bold">
									Dirección
								</SoftTypography>
							</SoftBox>
							<SoftInput
								placeholder="Escribe aquí"
								multiline
								rows={5}
								defaultValue={profile.address}
								onChange={(e) => handleChange(e, "address")}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
								<SoftTypography component="label" variant="caption" fontWeight="bold">
									Descripción de la empresa
								</SoftTypography>
							</SoftBox>
							<SoftInput placeholder="Escribe aquí" multiline rows={5} defaultValue={profile.description} 
							onChange={(e) => handleChange(e, "description")}
							/>
						</Grid>
						<Grid item xs={12} sm={12}>
							<SoftBox mb={3}>
								<SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
									<SoftTypography
										component="label"
										variant="caption"
										fontWeight="bold"
										textTransform="capitalize"
									>
										RUT
									</SoftTypography>
								</SoftBox>
								<SoftDropzone options={{ addRemoveLinks: true, placeholder: "hola" }}  defaultValue={profile.rut} 
								onChange={(e) => handleChange(e, "rut")}
								/>
							</SoftBox>
							<SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
								<SoftTypography
									component="label"
									variant="caption"
									fontWeight="bold"
									textTransform="capitalize"
								>
									Color
								</SoftTypography>
							</SoftBox>
							<SoftSelect
								defaultValue={{ value: "black", label: "Black" }}
								options={[
									{ value: "black", label: "Black" },
									{ value: "blue", label: "Blue" },
									{ value: "green", label: "Green" },
									{ value: "orange", label: "Orange" },
									{ value: "white", label: "White" },
								]}
							/>
						</Grid>
					</Grid>
				</SoftBox>
			</SoftBox>
		</Card>
	);
}

export default ProfileInfo;
