import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";

function Multimedia({ formData }) {
  const { formField, values, errors, touched, setFieldValue } = formData;

  const { image } = formField;
  const { image: imageV } = values;

  // Handle file change
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFieldValue("image", file); // Update the form field
    }
  };

  return (
    <SoftBox>
      <SoftTypography variant="h5" fontWeight="bold">
        Archivos Multimedia
      </SoftTypography>
      <SoftBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <SoftInput
              type="file" // Change to file type
              label={image.label}
              name={image.name}
              placeholder={image.placeholder}
              error={errors.image && touched.image}
              success={values.image && !errors.image}
              inputProps={{ autoComplete: "" }}
              onChange={handleFileChange} // Handle file change
            />
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

// PropType validation
Multimedia.propTypes = {
  formData: PropTypes.shape({
    formField: PropTypes.object,
    values: PropTypes.object,
    errors: PropTypes.object,
    touched: PropTypes.object,
    setFieldValue: PropTypes.func,
  }).isRequired,
};

export default Multimedia;
