import {
		GET_ENTERPRISE_SUCCESS,
		GET_ENTERPRISE_FAIL
} from "./types"

import axios from 'axios';
export const get_profile = (id) => async dispatch => {
	try {
		const config = {
			headers: {
				Accept: "application/json",
				Authorization: `JWT ${localStorage.getItem("access")}`,
			},
		};
		const res = await axios.get(`${process.env.REACT_APP_API_URL}/enterprise/profile/${id}/`, config);
		if (res.status === 200) {
			dispatch({
				type: GET_ENTERPRISE_SUCCESS,
				payload: res.data
			});
		} else {
			dispatch({
				type: GET_ENTERPRISE_FAIL
			});
		}
	} catch (err) {
		dispatch({
			type: GET_ENTERPRISE_FAIL
		});
	}
}