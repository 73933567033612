import * as Yup from "yup";
import checkout from "layouts/ecommerce/jobs/new-job/schemas/form"

const {
  formField: { title,description},
} = checkout;

const validations = [
  Yup.object().shape({
		[title.name]: Yup.string().required(title.errorMsg),
  }),
  
];

export default validations;
