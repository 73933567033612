import { useState } from "react";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import SoftAvatar from "components/SoftAvatar";
import SoftTypography from "components/SoftTypography";
import borders from "assets/theme/base/borders";
import dayjs from "dayjs";

function Post({ products }) {
	const { borderWidth, borderColor } = borders;

	return (
		<>
			{products &&
				products.map((product, index) => (
					<Grid item xs={12} md={6} lg={6} order={{xs: 2, lg: 1}} key={index}>
						<SoftBox>
							<Link to={`/product-details/${product.id}`}>
								<Card>
									<SoftBox
										display="flex"
										justifyContent="start"
										alignItems="center"
										flexDirection="row"
										borderBottom={`${borderWidth[1]} solid ${borderColor}`}
										py={2}
										px={3}
									>
										<SoftAvatar src={product.user.picture} alt="profile-image" variant="rounded" />
										<SoftBox mx={2} lineHeight={1}>
											<SoftTypography component="a" href="#" variant="button" fontWeight="regular">
												{product.user.enterprise}
											</SoftTypography>
											<SoftTypography component="div" variant="button" color="text" fontWeight="regular">
												{dayjs(product.created).format("DD/MM/YYYY - hh:mm A")}
											</SoftTypography>
										</SoftBox>
									</SoftBox>
									<SoftBox p={3}>
										<Grid container spacing={3}>
											<Grid item xs={12} lg={4} xl={4}>
												<SoftBox borderRadius="xl" height="150px" overflow="hidden" display="flex" justifyContent="center" alignItems="center">
													<img src={`${product.image}`} height="150%" />
												</SoftBox>
											</Grid>
											<Grid item xs={12} lg={8} xl={8}>
												<SoftTypography variant="body1" color="text">
														{product.name}
												</SoftTypography>
												<SoftBox>
													<SoftTypography variant="body2" color="text" sx={{overflow: "hidden", textOverflow: "ellipsis", display: "-webkit-box", WebkitLineClamp: "3", WebkitBoxOrient: "vertical"}}>
														{product.description}
													</SoftTypography>
												</SoftBox>
											</Grid>
										</Grid>
									</SoftBox>
								</Card>
							</Link>
						</SoftBox>
					</Grid>
				))}
		</>
	);
}

export default Post;
