// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// EditProduct page components
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";

function Socials({profile, onProfileChange}) {
	const handleChange = (eventOrValue, fieldName) => {
		// Verifica si el argumento es un evento (tiene target) o un valor directo
		const value = eventOrValue.target ? eventOrValue.target.value : eventOrValue;
		onProfileChange(fieldName, value);
	};
  return (
    <Card>
      <SoftBox p={3}>
        <SoftTypography variant="h5" fontWeight="bold">
          Redes Sociales
        </SoftTypography>
        <SoftBox mt={1}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormField type="text" label="facebook" defaultValue={profile.facebook} 
							onChange={(e) => handleChange(e, "facebook")} 
							/>
            </Grid>
            <Grid item xs={12}>
              <FormField type="text" label="x" defaultValue={profile.X} 
							onChange={
								(e) => handleChange(e, "X")
							}
								/>
            </Grid>
            <Grid item xs={12}>
              <FormField type="text" label="instagram" defaultValue={profile.instagram} 
							onChange={
								(e) => handleChange(e, "instagram")
							}/>
            </Grid>
          </Grid>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

export default Socials;
