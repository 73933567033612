import checkout from "layouts/ecommerce/products/new-product/schemas/form";

const {
	formField: {
		name,
		description,
		image,
		category,
		subcategory,
		extracategory,
	},
} = checkout;

const initialValues = {
	[name.name]: "",
	[description.name]: "",
	[image.name]: "",
	[category.name]: "",
	[subcategory.name]: "",
	[extracategory.name]: "",
};

export default initialValues;
