// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI dashboards PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// NewUser page components
import FormField from "layouts/dashboards/users/new-user/components/FormField";
import SoftSelect from "components/SoftSelect";
import { useSelector } from "react-redux";

function UserInfo({ formData }) {
	const { formField, values, errors, touched, setFieldValue } = formData;
	const { firstName, lastName, nuip, enterprise, email, password, repeatPassword } = formField;
	const user = useSelector((state) => state.auth.user);
	// Actualiza automáticamente los valores de 'role' y 'enterprise'

	const {
		firstName: firstNameV,
		lastName: lastNameV,
		nuip: nuipV,
		enterprise: enterpriseV,
		email: emailV,
		password: passwordV,
		repeatPassword: repeatPasswordV,
	} = values;
	
	const selectData = {
		gender: [
			{ value: "Masculino", label: "Masculino" },
			{ value: "Femenino", label: "Femenino" },
			{ value: "Otro", label: "Otro" },
		],
		document_type: [
			{ value: "CC", label: "CEDULA DE CIUDADANIA" },
			{ value: "CE", label: "CEDULA DE EXTRANJERIA" },
			{ value: "PA", label: "PASAPORTE" },
			{ value: "TI", label: "TARJETA DE IDENTIDAD" },
			{ value: "RC", label: "REGISTRO CIVIL" },
			{ value: "PE", label: "PERMISO ESPECIAL DE PERMANENCIA" },
			{ value: "PT", label: "PERMISO TEMPORAL DE PERMANENCIA" },
		],
	};

	return (
		<SoftBox>
			<SoftBox lineHeight={0}>
				<SoftTypography variant="h5" fontWeight="bold">
					Sobre mi
				</SoftTypography>
				<SoftTypography variant="button" fontWeight="regular" color="text">
					Informacion obligatoria
				</SoftTypography>
			</SoftBox>
			<SoftBox mt={1.625}>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={6}>
						<SoftBox mb={1.5}>
							<SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
								<SoftTypography
									component="label"
									variant="caption"
									fontWeight="bold"
									textTransform="capitalize"
								>
									Tipo de documento
								</SoftTypography>
							</SoftBox>
							<SoftSelect
								placeholder="Selecciona una opción"
								options={selectData.document_type}
								value={values.document_type}
								onChange={(value) => setFieldValue("document_type", value)}
							/>
						</SoftBox>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormField
							type={nuip.type}
							label={nuip.label}
							name={nuip.name}
							value={nuipV}
							placeholder={nuip.placeholder}
							error={errors.nuip && touched.nuip}
							success={nuip.length > 0 && !errors.nuip}
						/>
					</Grid>
				</Grid>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={6}>
						<FormField
							type={firstName.type}
							label={firstName.label}
							name={firstName.name}
							value={firstNameV}
							placeholder={firstName.placeholder}
							error={errors.firstName && touched.firstName}
							success={firstNameV.length > 0 && !errors.firstName}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormField
							type={lastName.type}
							label={lastName.label}
							name={lastName.name}
							value={lastNameV}
							placeholder={lastName.placeholder}
							error={errors.lastName && touched.lastName}
							success={lastNameV.length > 0 && !errors.lastName}
						/>
					</Grid>
				</Grid>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={6}>
						<SoftBox mb={1.5}>
							<SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
								<SoftTypography
									component="label"
									variant="caption"
									fontWeight="bold"
									textTransform="capitalize"
								>
									Genero
								</SoftTypography>
							</SoftBox>
							<SoftSelect
								placeholder="Selecciona una opcion"
								options={selectData.gender}
								onChange={(value) => setFieldValue("gender", value)}
							/>
						</SoftBox>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormField
							type={email.type}
							label={email.label}
							name={email.name}
							value={emailV}
							placeholder={email.placeholder}
							error={errors.email && touched.email}
							success={emailV.length > 0 && !errors.email}
						/>
					</Grid>

					{user && user.role === "Admin" && (
						<Grid item xs={12} sm={6}>
							<FormField
								type={enterprise.type}
								label={enterprise.label}
								name={enterprise.name}
								value={enterpriseV}
								placeholder="Nombre de la empresa"
								error={errors.enterprise && touched.enterprise}
							/>
						</Grid>
					)}
				</Grid>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={6}>
						<FormField
							type={password.type}
							label={password.label}
							name={password.name}
							value={passwordV}
							placeholder={password.placeholder}
							error={errors.password && touched.password}
							success={passwordV.length > 0 && !errors.password}
							inputProps={{ autoComplete: "" }}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormField
							type={repeatPassword.type}
							label={repeatPassword.label}
							name={repeatPassword.name}
							value={repeatPasswordV}
							placeholder={repeatPassword.placeholder}
							error={errors.repeatPassword && touched.repeatPassword}
							success={repeatPasswordV.length > 0 && !errors.repeatPassword}
							inputProps={{ autoComplete: "" }}
						/>
					</Grid>
				</Grid>
			</SoftBox>
		</SoftBox>
	);
}

// typechecking props for UserInfo
UserInfo.propTypes = {
	formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default UserInfo;
