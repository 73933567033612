import {
	PRODUCTS_LIST_SUCCESS,
	PRODUCTS_LIST_FAIL,
	GET_PRODUCT_SUCCESS,
	GET_PRODUCT_FAIL,
} from "../actions/products/types";

const initialState = {
	products: null,
	product: null,
	count: null,
  next: null,
  previous: null,
};


export default function products(state = initialState, action) {
	const { type, payload } = action;
	
	switch	(type) {
		case PRODUCTS_LIST_SUCCESS:
			return {
				...state,
				products: payload.results.products,
				count: payload.count,
				next: payload.next,
				previous: payload.previous,
			};
		case PRODUCTS_LIST_FAIL:
			return {
				...state,
				products: null,
				count: null,
				next: null,
				previous: null,
			};
		case GET_PRODUCT_SUCCESS:
			return {
				...state,
				product: payload.product,
			};
		case GET_PRODUCT_FAIL:
			return {
				...state,
				product: null,
			};
		default:
			return state;
	}
}
