import { useState } from "react";

// react-images-viewer components
import ImgsViewer from "react-images-viewer";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Images

function JobImages(job) {
  const [currentImage, setCurrentImage] = useState(job.source);
  const [imgsViewer, setImgsViewer] = useState(false);
  const [imgsViewerCurrent, setImgsViewerCurrent] = useState(0);
	console.log(job);
  const handleSetCurrentImage = ({ currentTarget }) => {
    setCurrentImage(currentTarget.src);
    setImgsViewerCurrent(Number(currentTarget.id));
  };

  const openImgsViewer = () => setImgsViewer(true);
  const closeImgsViewer = () => setImgsViewer(false);
  const imgsViewerNext = () => setImgsViewerCurrent(imgsViewerCurrent + 1);
  const imgsViewerPrev = () => setImgsViewerCurrent(imgsViewerCurrent - 1);

  return (
    <SoftBox>
      <ImgsViewer
        imgs={[{ src: job.source }]}
        isOpen={imgsViewer}
        onClose={closeImgsViewer}
        currImg={imgsViewerCurrent}
        onClickPrev={imgsViewerPrev}
        onClickNext={imgsViewerNext}
        backdropCloseable
      />

      <SoftBox
        component="img"
        src={currentImage}
        alt="Job Image"
        shadow="lg"
        borderRadius="lg"
        width="100%"
        onClick={openImgsViewer}
      />
      {/* <SoftBox mt={2} pt={1}>
        <Grid container spacing={3}>
          <Grid item xs={2}>
            <SoftBox
              component="img"
              id={0}
              src={job.source}
              alt="small image 1"
              borderRadius="lg"
              shadow="md"
              width="100%"
              sx={{ cursor: "pointer", height: "100%", objectFit: "cover" }}
              onClick={handleSetCurrentImage}
            />
          </Grid>
        </Grid>
      </SoftBox> */}
    </SoftBox>
  );
}

export default JobImages;
