/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { Link, Navigate, useNavigate } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import curved9 from "assets/images/curved-images/curved9.jpg";
import { connect } from "react-redux";
import {
	login,
	isAuthenticated,
	refresh,
	check_authenticated,
	load_user,
} from "redux/actions/auth/auth";
import Swal from "sweetalert2";

function Cover({ login, isAuthenticated, refresh, check_authenticated, load_user }) {
	const navigate = useNavigate();
	useEffect(() => {
		isAuthenticated ? (
			<></>
		) : (
			<>
				{refresh()}
				{check_authenticated()}
				{load_user()}
			</>
		);
	}, [isAuthenticated, refresh, check_authenticated, load_user]);

	useEffect(() => {
		if (isAuthenticated) {
			Swal.fire("Has Iniciado Sesión!", "", "success");

			navigate("/dashboard/benefits"); // Cambia '/dashboard' por la ruta a la que deseas redirigir
		}
	}, [isAuthenticated, navigate]);

	const [formData, setFormData] = useState({
		email: "",
		password: "",
	});

	const { email, password } = formData;

	const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

	const onSubmit = (e) => {
		e.preventDefault();
		login(email, password);
	};

	// const loginWithGoogle = async () => {
	//   try {
	//     const res = await axios.get(
	//       `${process.env.REACT_APP_API_URL}/auth/o/google-oauth2/?redirect_uri=${process.env.REACT_APP_API_URL}/dashboard`
	//     );
	//     window.location.replace(res.data.authorization_url);
	//   } catch (err) {
	//     Toast({ tipo: "error", mensaje: "Error al iniciar sesion con Google" });
	//   }
	// };

	const [rememberMe, setRememberMe] = useState(true);

	const handleSetRememberMe = () => setRememberMe(!rememberMe);

	return (
		<CoverLayout
			title="¡Bienvenido!"
			description="Inicia sesión para ver lo que otras empresas tienen para ofrecerte."
			image={curved9}
		>
			<SoftBox component="form" role="form" onSubmit={onSubmit}>
				<SoftBox mb={2} lineHeight={1.25}>
					<SoftBox mb={1} ml={0.5}>
						<SoftTypography component="label" variant="caption" fontWeight="bold">
							Correo
						</SoftTypography>
					</SoftBox>
					<SoftInput
						type="email"
						placeholder="Correo electrónico"
						name="email"
						value={formData.email}
						onChange={onChange}
					/>
				</SoftBox>
				<SoftBox mb={2} lineHeight={1.25}>
					<SoftBox mb={1} ml={0.5}>
						<SoftTypography component="label" variant="caption" fontWeight="bold">
							Contraseña
						</SoftTypography>
					</SoftBox>
					<SoftInput
						type="password"
						placeholder="Contraseña"
						name="password"
						value={formData.password}
						onChange={onChange}
					/>
				</SoftBox>
				<SoftBox display="flex" alignItems="center">
					<Switch checked={rememberMe} onChange={handleSetRememberMe} />
					<SoftTypography
						variant="button"
						fontWeight="regular"
						onClick={handleSetRememberMe}
						sx={{ cursor: "pointer", userSelect: "none" }}
					>
						&nbsp;&nbsp;Recuérdame
					</SoftTypography>
				</SoftBox>
				<SoftBox mt={4} mb={1}>
					<SoftButton type="submit" variant="gradient" color="success" fullWidth>
						Ingresar
					</SoftButton>
				</SoftBox>
				<SoftBox mt={3} textAlign="center">
					<SoftTypography
						component={Link}
						to="/reset-password"
						variant="button"
						color="success"
						fontWeight="medium"
						textGradient
					>
						¿Olvidaste tu contraseña?
					</SoftTypography>
				</SoftBox>
			</SoftBox>
		</CoverLayout>
	);
}

const mapStateToProps = (state) => ({
	isAuthenticated: state.auth.isAuthenticated,
	loading: state.auth.loading,
});

export default connect(mapStateToProps, {
	login,
	refresh,
	check_authenticated,
	load_user,
})(Cover);
