// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { get_user_list, get_user_list_page } from "redux/actions/user/user";
import { Link } from "react-router-dom";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import Swal from "sweetalert2";
import axios from "axios";
// Data
// import dataTableData from "layouts/applications/data-tables/data/dataTableData";

function UsersTable({ get_user_list, get_user_list_page, users, count }) {
	const [reloadKey, setReloadKey] = useState(0);

	// useEffect que depende de reloadKey
	useEffect(() => {
		get_user_list();
	}, [reloadKey, get_user_list]);

	function formatDate(dateString) {
		const options = { year: "numeric", month: "long", day: "numeric" };
		return new Date(dateString).toLocaleDateString("es", options);
	}

	const onSubmit = (e, email) => {
		const config = {
			headers: {
				Accept: "application/json",
				Authorization: `JWT ${localStorage.getItem("access")}`,
			},
		};

		const formData = new FormData();
		formData.append("email", email);

		const fetchData = async () => {
			try {
				const res = await axios.post(
					`${process.env.REACT_APP_API_URL}/auth/users/resend_activation/`,
					formData,
					config
				);
				if (res.status === 204) {
					Swal.fire("¡Enviado!","","success")

				} else {
					Swal.fire("¡Error al enviar la activacion al user!","","error")
				}
			} catch (err) {
				if (
					err.response &&
					err.response.status === 400 &&
					err.response.data.error.includes("Invalid credentials.")
				) {
					Swal.fire("¡Credenciales incorrectas!","","error")
				}
				if (
					err.response &&
					err.response.status === 403 &&
					err.response.data.error.includes("User is not active.")
				) {
					Swal.fire("¡El usuario no está activo!","","error")
				}
			}
		};
		fetchData();
	};

	const dataTableData = {
		columns: [
			{ Header: "Nombre", accessor: "nombre", width: "20%" },
			{ Header: "Cédula", accessor: "cedula" },
			{ Header: "Fecha de Registro", accessor: "fecha_de_registro" },
			{ Header: "Asignación", accessor: "asignacion" },
			{ Header: "Acciones", accessor: "acciones" },
		],
		rows: users
			? users.map((user) => ({
					nombre: user.first_name + " " + user.last_name,
					cedula: user.document_type + "-" + user.nuip,
					fecha_de_registro: formatDate(user.date_joined),
					asignacion: user.role && user.role === "employees" ? "Empleado" : "Empresa",
					acciones: (
						<SoftBox display="flex" justifyContent="start" gap={1}>
							<SoftBox
								component={Card}
								borderRadius="xl"
								boxShadow="regular"
								width="fit-content"
								p={1}
							>
								{/* Example content inside SoftBox */}
								<Link to={`/user-edit/${user.id}`}>
									<SoftTypography
										variant="body1"
										color="secondary"
										sx={{ cursor: "pointer", lineHeight: 0 }}
									>
										<Tooltip title="Editar" placement="top">
											<Icon>edit</Icon>
										</Tooltip>
									</SoftTypography>
								</Link>
								{/* Add more buttons or content as needed */}
							</SoftBox>
							{!user.is_active && !user.is_active ? (
								<SoftBox
									component={Card}
									borderRadius="xl"
									boxShadow="regular"
									width="fit-content"
									p={1}
									onClick={(e) => onSubmit(e, user.email)}
								>
									<SoftTypography
										variant="body1"
										color="secondary"
										sx={{ cursor: "pointer", lineHeight: 0 }}
									>
										<Tooltip title="Reenviar código" placement="top">
											<Icon>send</Icon>
										</Tooltip>
									</SoftTypography>
								</SoftBox>
							) : (
								""
							)}
						</SoftBox>
					),
			  }))
			: [],
	};

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<SoftBox pt={6} pb={3}>
				<Card>
					<SoftBox p={3} lineHeight={1}>
						<SoftTypography variant="h5" fontWeight="medium">
							Listado de Usuarios registrados
						</SoftTypography>
						<SoftTypography variant="button" fontWeight="regular" color="text">
							Corpoindustrial Élite
						</SoftTypography>
					</SoftBox>
					<DataTable table={dataTableData} canSearch />
				</Card>
			</SoftBox>
			<Footer />
		</DashboardLayout>
	);
}
const mapStateToProps = (state) => ({
	users: state.users.users,
	count: state.users.count,
});
export default connect(mapStateToProps, {
	get_user_list,
	get_user_list_page,
})(UsersTable);
