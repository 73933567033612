import {
		GET_JOB_SUCCESS,
		GET_JOB_FAIL,
		JOBS_LIST_SUCCESS,
		JOBS_LIST_FAIL,
} from './types';
import axios from 'axios';


export const jobsList = (enterpriseId = null) => async dispatch => {
	try {
		const config = {
			headers: {
				Accept: "application/json",
				Authorization: `JWT ${localStorage.getItem("access")}`,
			},
		};
		let url = `${process.env.REACT_APP_API_URL}/job/list/`;
    if (enterpriseId) {
        url += `?enterprise_id=${enterpriseId}`;
    }
    const res = await axios.get(url, config);
		if (res.status === 200) {
			dispatch({
				type: JOBS_LIST_SUCCESS,
				payload: res.data
			});
		} else {
			dispatch({
				type: JOBS_LIST_FAIL
			});
		}
	} catch (err) {
		dispatch({
			type: JOBS_LIST_FAIL
		});
	}
};

export const jobsListPage = (p) => async dispatch => {
	try {
		const config = {
			headers: {
				Accept: "application/json",
				Authorization: `JWT ${localStorage.getItem("access")}`,
			},
		};
		const res = await axios.get(`${process.env.REACT_APP_API_URL}/job/list/?page=${p}`, config);
		if (res.status === 200) {
			dispatch({
				type: JOBS_LIST_SUCCESS,
				payload: res.data
			});
		} else {
			dispatch({
				type: JOBS_LIST_FAIL
			});
		}
	} catch (err) {
		dispatch({
			type: JOBS_LIST_FAIL
		});
	}

}
export const getJob = (id) => async dispatch => {
	try {
		const config = {
			headers: {
				Accept: "application/json",
				Authorization: `JWT ${localStorage.getItem("access")}`,
			},
		};
		const res = await axios.get(`${process.env.REACT_APP_API_URL}/job/${id}/`, config);
		if (res.status === 200) {
			dispatch({
				type: GET_JOB_SUCCESS,
				payload: res.data
			});
		} else {
			dispatch({
				type: GET_JOB_FAIL
			});
		}
	} catch (err) {
		dispatch({
			type: GET_JOB_FAIL
		});
	}
}