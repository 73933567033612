import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftEditor from "components/SoftEditor";
import SoftSelect from "components/SoftSelect";

// NewProduct page components
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";

function ProductInfo({ product, onProductChange }) {
	const handleChange = (eventOrValue, fieldName) => {
    // Verifica si el argumento es un evento (tiene target) o un valor directo
    const value = eventOrValue.target ? eventOrValue.target.value : eventOrValue;
    onProductChange(fieldName, value);
};


	const selectData = {
		category: [
			{ value: "cat1", label: "cat1" },
			{ value: "cat2", label: "cat2" },
			{ value: "cat3", label: "cat3" },
		],
		subcategory: [
			{ value: "subcat1", label: "subcat1" },
			{ value: "subcat2", label: "subcat2" },
			{ value: "subcat3", label: "subcat3" },
		],
		extracategory: [
			{ value: "extracat1", label: "extracat1" },
			{ value: "extracat2", label: "extracat2" },
			{ value: "extracat3", label: "extracat3" },
		],
	};

	return (
		<Card sx={{ overflow: "visible" }}>
			<SoftBox p={3}>
				<SoftTypography variant="h5">Información del Beneficio</SoftTypography>
				<SoftBox mt={1}>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={6}>
							<FormField
								type="text"
								label="Nombre del beneficio"
								defaultValue={product.name}
								onChange={(e) => handleChange(e, "name")}
							/>
						</Grid>

						<Grid item xs={12} sm={6}>
							<SoftTypography
								component="label"
								variant="caption"
								fontWeight="bold"
								textTransform="capitalize"
							>
								Categoria
							</SoftTypography>
							<SoftSelect
								defaultValue={{ value: product.category, label: product.category }}
								options={selectData.category}
								onChange={(value) => handleChange(value, "category")}
							/>
						</Grid>
					</Grid>
				</SoftBox>

				<SoftBox mt={1}>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={6}>
							<FormField
								type="text"
								multiline
								rows={5}
								label="Descripcion del servicio"
								defaultValue={product.description}
								onChange={(e) => handleChange(e, "description")}
							/>
						</Grid>

						<Grid item xs={12} sm={6}>
							<SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
								<SoftTypography
									component="label"
									variant="caption"
									fontWeight="bold"
									textTransform="capitalize"
								>
									Sub-categoria
								</SoftTypography>
							</SoftBox>
							<SoftSelect
								defaultValue={{ value: product.subcategory, label: product.subcategory }}
								options={selectData.subcategory}
								onChange={(value) => handleChange(value, "subcategory")}
							/>
							<SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
								<SoftTypography
									component="label"
									variant="caption"
									fontWeight="bold"
									textTransform="capitalize"
								>
									Categoria Adicional
								</SoftTypography>
							</SoftBox>
							<SoftSelect
								defaultValue={{ value: product.extracategory, label: product.extracategory }}
								options={selectData.extracategory}
								onChange={(value) => handleChange(value, "extracategory")}
							/>
						</Grid>
					</Grid>
				</SoftBox>
			</SoftBox>
		</Card>
	);
}

export default ProductInfo;
