import { Skeleton } from "@mui/material";
import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

function ProfileHeader() {
  return(
    <Grid container spacing={3} alignItems="center">
      <Grid item>
        <Skeleton variant="rounded" animation="wave" width={74} height={74} />
      </Grid>
      <Grid item>
        <SoftBox height="100%" mt={0.5} lineHeight={1}>
          <SoftTypography variant="h5" fontWeight="medium">
            <Skeleton variant="rectangular" animation="wave" width={210} height={118} />
          </SoftTypography>
          <SoftTypography variant="button" color="text" fontWeight="medium">
            <Skeleton variant="rectangular" animation="wave" width={210} height={118} />
          </SoftTypography>
        </SoftBox>
      </Grid>
    </Grid>
  )
}

export default ProfileHeader;