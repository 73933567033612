/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import { useState } from "react";


function ProductImage({ image, setProductData }) {
  const [isEditing, setIsEditing] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const newImage = event.target.files[0];
      setSelectedImage(URL.createObjectURL(newImage));
      setProductData(prevData => ({ ...prevData, image: newImage }));
    }
  };

  const toggleEdit = () => setIsEditing(!isEditing);

  return (
    <Card sx={{ height: "100%" }}>
      <SoftBox p={3} display="flex" flexDirection="column" alignItems="center">
        <SoftTypography variant="h5" fontWeight="bold">
          Imagen del Beneficio
        </SoftTypography>
        <SoftBox
          width="200px"
          maxHeight="200px"
          my={3}
          alignSelf="center"
          overflow="hidden"
          borderRadius="lg"
          display="flex"
          justifyContent="center"
        >
          <SoftBox
            component="img"
            src={isEditing && selectedImage ? selectedImage : image.image}
            alt="Job Image"
            shadow="lg"
            height="100%"
            borderRadius="lg"
          />
        </SoftBox>
        {isEditing ? (
         <SoftBox my={2}>
					<input type="file" onChange={handleImageChange} />
				 </SoftBox>
        ) : null}
        <SoftBox display="flex">
          <SoftBox mr={1}>
            <SoftButton variant="gradient" color="info" size="small" onClick={toggleEdit}>
              {isEditing ? 'Cancelar' : 'Editar'}
            </SoftButton>
          </SoftBox>
          {isEditing ? (
            <SoftButton variant="outlined" color="dark" size="small" onClick={() => setIsEditing(false)}>
              Guardar
            </SoftButton>
          ) : null}
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

export default ProductImage;