// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import PageLayout from "examples/LayoutContainers/PageLayout";

// Authentication layout components
import Footer from "layouts/authentication/components/Footer";

// Soft UI Dashboard PRO React page layout routes
// import pageRoutes from "page.routes";

import { connect } from "react-redux";

import {
	login,
	isAuthenticated,
	refresh,
	check_authenticated,
	load_user,
	reset_password_confirm,
} from "redux/actions/auth/auth";

import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

function Basic({
	reset_password_confirm,
	isAuthenticated,
	refresh,
	check_authenticated,
	load_user,
}) {
	const params = useParams();
	const uid = params.uid;
	const token = params.token;

	useEffect(() => {
		isAuthenticated ? (
			<></>
		) : (
			<>
				{refresh()}
				{check_authenticated()}
				{load_user()}
			</>
		);
	}, [refresh, check_authenticated, load_user, isAuthenticated]);

	const [formData, setFormData] = useState({
		new_password: "",
		re_new_password: "",
	});

	const { new_password, re_new_password } = formData;

	const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

	const navigate = useNavigate();

	const onSubmit = (e) => {
		e.preventDefault();
		reset_password_confirm(uid, token, new_password, re_new_password);
		navigate("/");
	};

	if (isAuthenticated) {
		navigate("/");
	}

	return (
		<PageLayout background="light">
			{/* <DefaultNavbar
				routes={pageRoutes}
				action={{
					type: "external",
					route: "/login",
					label: "Login",
				}}
			/> */}
			<Grid container spacing={3} justifyContent="center" sx={{ minHeight: "75vh" }}>
				<Grid item xs={10} md={6} lg={4}>
					<SoftBox mt={32} mb={3} px={{ xs: 0, lg: 2 }}>
						<Card>
							<SoftBox pt={3} px={3} pb={1} textAlign="center">
								<SoftTypography variant="h4" fontWeight="bold" textGradient>
									Cambiar la Contraseña
								</SoftTypography>
								{/* Puedes agregar más SoftTypography si necesitas */}
							</SoftBox>
							<SoftBox p={3}>
								<SoftBox component="form" role="form" onSubmit={onSubmit}>
									<SoftBox mb={2}>
										<SoftInput
											type="password"
											placeholder="Nueva Contraseña"
											name="new_password"
											value={new_password}
											onChange={onChange}
										/>
									</SoftBox>
									<SoftBox mb={2}>
										<SoftInput
											type="password"
											placeholder="Repetir Contraseña"
											name="re_new_password"
											value={re_new_password}
											onChange={onChange}
										/>
									</SoftBox>
									<SoftBox mt={5} mb={1}>
										<SoftButton
											variant="gradient"
											color="dark"
											size="large"
											fullWidth
											type="submit"
										>
											Cambiar la Contraseña
										</SoftButton>
									</SoftBox>
								</SoftBox>
							</SoftBox>
						</Card>
					</SoftBox>
				</Grid>
			</Grid>
			<Footer />
		</PageLayout>
	);
}

const mapStateToProps = (state) => ({
	isAuthenticated: state.auth.isAuthenticated,
	loading: state.auth.loading,
});

export default connect(mapStateToProps, {
	reset_password_confirm,
	refresh,
	check_authenticated,
	load_user,
})(Basic);
