import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
// import Menu from "@mui/material/Menu";
// import MenuItem from "@mui/material/MenuItem";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
// import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import TeamProfileCard from "examples/Cards/TeamCards/TeamProfileCard";
// import EventCard from "examples/Cards/EventCard";

// Teams page components
import Header from "layouts/pages/profile/components/Header";
import Stories from "layouts/pages/profile/teams/components/Stories";
import Post from "layouts/pages/profile/teams/components/Post";
import Pagination from "@mui/material/Pagination";
import Card from "@mui/material/Card";
import SoftTypography from "components/SoftTypography";

import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
	get_user_dashboard,
	get_product_dashboard,
	get_job_dashboard,
} from "redux/actions/dashboard/dashboard";
import { connect } from "react-redux";

function Teams({
	get_user_dashboard,
	users,
	get_product_dashboard,
	products,
	get_job_dashboard,
	jobs,
}) {
	const user = useSelector((state) => state.auth.user);

	useEffect(() => {
		get_user_dashboard();
		get_product_dashboard();
		get_job_dashboard();
	}, [get_user_dashboard, get_product_dashboard, get_job_dashboard]);

	return (
		<DashboardLayout>
			<Header user={user} />
			<SoftBox my={3}>
				{/* <Stories users={users} /> */}
				<Card>
					<SoftBox p={2}>
						<SoftTypography variant="h6" fontWeight="medium" textGradient>
							Beneficios
						</SoftTypography>
					</SoftBox>
				</Card>
			</SoftBox>
			<SoftBox mb={3}>
				<Grid container spacing={3}>
					<Post products={products}/>
					{/* <Grid item xs={12} lg={5} order={{xs: 1, lg: 2}}>
						<Stack direction={{xs: 'row', lg: 'column'}} overflow="auto" px={2}>
							{jobs &&
								jobs.map((job, index) => (
									<Grid item minWidth="95%" xs={12} lg={12} key={index}>
										<SoftBox mb={3}>
											<Link to={`/job-details/${job.id}`}>
												<TeamProfileCard
													title={job.title}
													description={job.description}
													industry={job.user.enterprise}
													rating={job.priority === "Baja" ? 1 : job.priority === "Media" ? 2 : 3}
												/>
											</Link>
										</SoftBox>
									</Grid>
								))}
						</Stack>
					</Grid> */}
				</Grid>
			</SoftBox>
			<SoftBox display="flex" justifyContent="center" my={2}>
				<Pagination count={10} color="success" />
			</SoftBox>
			<Footer />
		</DashboardLayout>
	);
}

const mapStateToProps = (state) => ({
	users: state.dashboard.user,
	products: state.dashboard.product,
	jobs: state.dashboard.job,
});

export default connect(mapStateToProps, {
	get_user_dashboard,
	get_product_dashboard,
	get_job_dashboard,
})(Teams);
