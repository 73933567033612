import checkout from "layouts/ecommerce/jobs/new-job/schemas/form";

const {
	formField: { title, description, image },
} = checkout;

const initialValues = {
	[title.name]: "",
	[description.name]: "",
	[image.name]: "",
};

export default initialValues;
