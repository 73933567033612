// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { Divider } from "@mui/material";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";
import InstagramIcon from "@mui/icons-material/Instagram";
import typography from "assets/theme/base/typography";
import colors from "assets/theme/base/colors";
function ProductInfo(product) {
	const { enterprise } = product;
	const user = useSelector((state) => state.auth.user);
	const social = [
		{
			link: product.facebook,
			icon: <FacebookIcon />,
			color: "facebook",
			key: "social-facebook",
		},
		{
			link: product.instagram,
			icon: <InstagramIcon />,
			color: "instagram",
			key: "social-instagram",
		},
		{
			link: product.X,
			icon: <XIcon />,
			color: "twitter",
			key: "social-x",
		},
	];
	const { size } = typography;
	const { socialMediaColors } = colors;
	return (
		<SoftBox>
			<SoftBox mb={1}>
				<SoftTypography variant="h3" fontWeight="bold">
					{product.title}
				</SoftTypography>
			</SoftBox>
			<SoftBox mt={1} mb={1}>
				<SoftTypography fontWeight="bold">Descripción</SoftTypography>
			</SoftBox>
			<SoftBox m={0} mb={2} fontSize={16}>
				{product.description}
			</SoftBox>
			{user && user.id === enterprise ? (
				<Divider />
			) : (
				<>
					<Divider />
					<SoftBox mb={1}>
						<SoftTypography variant="h5" fontWeight="bold">
							Empresa: {enterprise.enterprise}
						</SoftTypography>
					</SoftBox>
					<SoftBox mb={1}>
						<SoftTypography fontSize={16}>
							Redes sociales:
							{social.map(({ link, icon, color }) => (
								<SoftBox
									key={color}
									component="a"
									href={link}
									target="_blank"
									rel="noreferrer"
									fontSize={size.lg}
									color={socialMediaColors[color].main}
									pr={1}
									pl={0.5}
									lineHeight={1}
								>
									{icon}
								</SoftBox>
							))}
						</SoftTypography>
					</SoftBox>
					<SoftBox mb={1}>
						<SoftTypography fontSize={16}>Dirección: {product.address}</SoftTypography>
					</SoftBox>
					<Divider />
				</>
			)}
			<SoftBox m={0} mb={2} fontSize={14}>
				Creado: {dayjs(product.created).format("DD/MM/YYYY - hh:mm A")}
			</SoftBox>
		</SoftBox>
	);
}

export default ProductInfo;
