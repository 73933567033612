import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SoftTypography from "components/SoftTypography";
import { Tooltip, Icon } from "@mui/material";
import SoftButton from "components/SoftButton";
import SoftBox from 'components/SoftBox';
import CardFrontImg from "assets/images/cie-card-front.jpg";
import CardBackImg from "assets/images/cie-card-back.jpg";
import Flippy, { FrontSide, BackSide } from 'react-flippy';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function CustomizedDialogs({name, enterprise}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Ver tarjeta" placement="bottom">
        <SoftButton variant="outlined" color='success' onClick={handleClickOpen}>
          <Icon>visibility</Icon>
        </SoftButton>
      </Tooltip>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          <SoftTypography variant="h6" fontWeight="medium">
            Tarjeta Corpoindustrial Élite
          </SoftTypography>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Flippy
            flipOnHover={false}
            flipOnClick={true}
          >
            <FrontSide
              style={{
                backgroundColor: 'transparent',
                boxShadow: 'none',
              }}
            >
              <SoftBox display="flex" justifyContent="center" overflow="hidden" borderRadius="16px" mb={2} >
                <img src={CardFrontImg} alt="Tarjeta CIE" width="285px" />
              </SoftBox>
            </FrontSide>
            <BackSide
              style={{
                backgroundColor: 'transparent',
                boxShadow: 'none',
              }}
            >
              <SoftBox display="flex" justifyContent="center" overflow="hidden" borderRadius="16px" mb={2} position="relative">
                <img src={CardBackImg} alt="Tarjeta CIE" width="285px" />
                <SoftBox style={{position: "absolute", top: 5}}>
                  <SoftTypography variant="h5" fontWeight="bold">
                    {name}
                  </SoftTypography>
                </SoftBox>
                <SoftBox style={{position: "absolute", top: 28}}>
                  <SoftTypography variant="body2">
                    {enterprise}
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
            </BackSide>
          </Flippy>
          <SoftTypography variant="body">
            Toca la tarjeta para ver el reverso
          </SoftTypography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cerrar
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}