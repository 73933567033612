
const form = {
  formId: "new-user-form",
  formField: {
    document_type: {
      name: "document_type",
      label: "Tipo de documento",
      type: "text",
      placeholder: "Ej. VE",
      errorMsg: "Tipo de documento es requerido.",
    },
    nuip: {
      name: "nuip",
      label: "Numero de documento",
      type: "text",
      placeholder: "Ej. 123456789",
      errorMsg: "Numero de documento es requerido.",
    },
    firstName: {
      name: "firstName",
      label: "Nombres",
      type: "text",
      placeholder: "Ej. Micheal",
      errorMsg: "Nombres es requerido.",
    },
    lastName: {
      name: "lastName",
      label: "Apellidos",
      type: "text",
      placeholder: "Ej. Prior",
      errorMsg: "Apellidos es requerido.",
    },
		gender : {
			name : "gender",
			label : "Genero",
			type : "text",
			placeholder : "Ej. Masculino",
			errorMsg : "Genero es requerido.",
		},
		enterprise: {
			name: "enterprise",
			label: "Empresa",
			type: "text",
			placeholder: "Ej. Soft",
			errorMsg: "Empresa es requerido.",
		
		},
    email: {
      name: "email",
      label: "E-mail",
      type: "email",
      placeholder: "Ej. soft@dashboard.come",
      errorMsg: "Email es requerido.",
      invalidMsg: "Su dirección de correo electrónico no es válida",
    },
    password: {
      name: "password",
      label: "Contraseña",
      type: "password",
      placeholder: "******",
      errorMsg: "Contraseña es requerida.",
      invalidMsg: "Tu contraseña debe tener más de 6 caracteres.",
    },
    repeatPassword: {
      name: "repeatPassword",
      label: "Repetir contraseña",
      type: "password",
      placeholder: "******",
      errorMsg: "Contraseña es requerida.",
      invalidMsg: "Tu contraseña no coincide.",
    },

		picture : {
			name : "picture",
			label : "Imagen",
			type : "file",
			placeholder : "Ej. Imagen",
			errorMsg : "Imagen es requerida.",
		},
		banner : {
			name : "banner",
			label : "Banner",
			type : "file",
			placeholder : "Ej. Banner",
			errorMsg : "Banner es requerido.",
		},
  },
};

export default form;
