// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// NewUser page components
import FormField from "layouts/ecommerce/products/new-product/components/FormField";
import SoftSelect from "components/SoftSelect";

function Categorias({ formData }) {
	const { formField, values, errors, touched, setFieldValue } = formData;

	const selectData = {
		category: [
			{ value: "cat1", label: "cat1" },
			{ value: "cat2", label: "cat2" },
			{ value: "cat3", label: "cat3" },
		],
		subcategory: [
			{ value: "subcat1", label: "subcat1" },
			{ value: "subcat2", label: "subcat2" },
			{ value: "subcat3", label: "subcat3" },
		],
		extracategory: [
			{ value: "extracat1", label: "extracat1" },
			{ value: "extracat2", label: "extracat2" },
			{ value: "extracat3", label: "extracat3" },
		],
	};
	return (
		<SoftBox>
			<SoftTypography variant="h5" fontWeight="bold">
				Categorias
			</SoftTypography>
			<SoftBox mt={1.625}>
				<Grid container spacing={1}>
					<Grid item xs={12} sm={6}>
						<SoftTypography
							component="label"
							variant="caption"
							fontWeight="bold"
							textTransform="capitalize"
						>
							Categoria
						</SoftTypography>
						<SoftSelect
							placeholder="Selecciona una opcion"
							options={selectData.category}
							onChange={(value) => setFieldValue("category", value)}
						/>
					</Grid>

					<Grid item xs={12} sm={6}>
						<SoftTypography
							component="label"
							variant="caption"
							fontWeight="bold"
							textTransform="capitalize"
						>
							Subcategoria
						</SoftTypography>
						<SoftSelect
							placeholder="Selecciona una opcion"
							options={selectData.subcategory}
							onChange={(value) => setFieldValue("subcategory", value)}
						/>
					</Grid>

					<Grid item xs={12} sm={6}>
						<SoftTypography
							component="label"
							variant="caption"
							fontWeight="bold"
							textTransform="capitalize"
						>
							Extracategoria
						</SoftTypography>
						<SoftSelect
							placeholder="Selecciona una opcion"
							options={selectData.extracategory}
							onChange={(value) => setFieldValue("extracategory", value)}
						/>
						</Grid>
				</Grid>
			</SoftBox>
		</SoftBox>
	);
}

// typechecking props for Categorias
Categorias.propTypes = {
	formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Categorias;
