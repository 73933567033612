/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";
// import SoftAvatar from "components/SoftAvatar";

function TeamProfileCard({ color, title, description, industry, rating }) {
  const ratings = {
    0.5: [
      <Icon key={1}>star_outline</Icon>,
      <Icon key={2}>star_outline</Icon>,
      <Icon key={3}>star_outline</Icon>,
    ],
    1: [
      <Icon key={1}>star</Icon>,
      <Icon key={2}>star_outline</Icon>,
      <Icon key={3}>star_outline</Icon>,
    ],
    1.5: [
      <Icon key={1}>star</Icon>,
      <Icon key={2}>star_half</Icon>,
      <Icon key={3}>star_outline</Icon>,
    ],
    2: [
      <Icon key={1}>star</Icon>,
      <Icon key={2}>star</Icon>,
      <Icon key={3}>star_outline</Icon>,
    ],
    2.5: [
      <Icon key={1}>star</Icon>,
      <Icon key={2}>star</Icon>,
      <Icon key={3}>star_half</Icon>,
    ],
    3: [
      <Icon key={1}>star</Icon>,
      <Icon key={2}>star</Icon>,
      <Icon key={3}>star</Icon>,
    ],
  };

  return (
    <Card>
      <SoftBox p={3}>
        <SoftBox display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <SoftTypography
            variant="h5"
            fontWeight="medium"
            color={"success"}
            textTransform="capitalize"
            textGradient
          >
            {title}
          </SoftTypography>
        </SoftBox>
        <SoftBox mb={2}>
          <SoftTypography variant="body2" color="text" sx={{overflow: "hidden", textOverflow: "ellipsis", display: "-webkit-box", WebkitLineClamp: "3", WebkitBoxOrient: "vertical"}} >
            {description}
          </SoftTypography>
        </SoftBox>
        <SoftBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {industry ? (
            <SoftBox component="li" display="flex" flexDirection="column">
              <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                <SoftTypography variant="body2" color="text">
                  Empresa:
                </SoftTypography>
                <SoftBadge
                  variant="contained"
                  color="secondary"
                  size="md"
                  badgeContent={industry}
                  container
                />
              </SoftBox>
              <Divider />
            </SoftBox>
          ) : null}
          {rating ? (
            <SoftBox component="li" display="flex" flexDirection="column">
              <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                <SoftTypography variant="body2" color="text">
                  Prioridad:
                </SoftTypography>
                <SoftBox
                  sx={({ functions: { pxToRem }, palette: { text } }) => ({
                    fontSize: pxToRem(24),
                    lineHeight: 0,
                    color: text.main,
                  })}
                >
                  {ratings[rating]}
                </SoftBox>
              </SoftBox>
              <Divider />
            </SoftBox>
          ) : null}
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

// Setting default values for the props of TeamProfileCard
TeamProfileCard.defaultProps = {
  color: "info",
  industry: "",
  rating: 0,
  dropdown: false,
};

// Typechecking props for the TeamProfileCard
TeamProfileCard.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  industry: PropTypes.string,
  rating: PropTypes.number,
  dropdown: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      action: PropTypes.func,
      menu: PropTypes.node,
    }),
  ]),
};

export default TeamProfileCard;
