// para obtener todos los USERes
export const GET_USER_LIST_SUCCESS = 'GET_USER_LIST_SUCCESS';
export const GET_USER_LIST_FAIL = 'GET_USER_LIST_FAIL';
// para obtener el USERe por uuid
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAIL = 'GET_USER_FAIL';


export const GET_USER_SEARCH_SUCCESS = 'GET_USER_SEARCH_SUCCESS';
export const GET_USER_SEARCH_FAIL = 'GET_USER_SEARCH_FAIL';

export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAIL = 'GET_PROFILE_FAIL';