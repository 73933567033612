import { useState } from "react";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// NewUser page components
import UserInfo from "layouts/dashboards/users/new-user/components/UserInfo";

// NewUser layout schemas for form and form feilds
import validations from "layouts/dashboards/users/new-user/schemas/validations";
import form from "layouts/dashboards/users/new-user/schemas/form";
import initialValues from "layouts/dashboards/users/new-user/schemas/initialValues";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Multimedia from "layouts/dashboards/users/new-user/components/Multimedia";

function getSteps(user) {
	const steps = ["Información de usuario"];

	// Agregar "Multimedia" si el rol del usuario es "Admin"
	if (user && user.role === "Admin") {
		steps.push("Multimedia");
	}

	return steps;
}

function getStepContent(stepIndex, formData, user) {
	switch (stepIndex) {
		case 0:
			return <UserInfo formData={formData} />;
		case 1:
			if (user && user.role === "Admin") {
				return <Multimedia formData={formData} />;
			}
			break;
		default:
			return null;
	}
}

function NewUser() {
	const [activeStep, setActiveStep] = useState(0);
	const navigate = useNavigate();
	const user = useSelector((state) => state.auth.user);
	const steps = getSteps(user);
	const { formId, formField } = form;
	const currentValidation = validations[activeStep];
	const isLastStep = activeStep === steps.length - 1;

	const urlToFile = async (url, filename, mimeType) => {
		const response = await fetch(url);
		const blob = await response.blob();
		return new File([blob], filename, { type: mimeType });
	};
	const sleep = (ms) =>
		new Promise((resolve) => {
			setTimeout(resolve, ms);
		});
	const handleBack = () => setActiveStep(activeStep - 1);

	const submitForm = async (values, actions) => {
		await sleep(1000); // Simula un retraso

		const enterprise = user && user.role === "Admin" ? values.enterprise : user.enterprise;
		const role = user && user.role === "Admin" ? "enterprise" : "employees";

		const picture =
			user && user.role === "Admin"
				? values.picture
				: await urlToFile(user.picture, "picture.png", "image/png");
		const banner =
			user && user.role === "Admin"
				? values.banner
				: (values.banner = await urlToFile(user.banner, "banner.png", "image/png"));

		const config = {
			headers: {
				Authorization: `JWT ${localStorage.getItem("access")}`,
				Accept: "application/json",
			},
		};

		const formData = new FormData();
		formData.append("first_name", values.firstName);
		formData.append("last_name", values.lastName);
		formData.append("document_type", values.document_type.value);
		formData.append("nuip", values.nuip);
		formData.append("email", values.email);
		formData.append("password", values.password);
		formData.append("re_password", values.repeatPassword);
		formData.append("username", values.email);
		formData.append("enterprise", enterprise);
		formData.append("role", role);
		formData.append("gender", values.gender.value);
		formData.append("picture", picture);
		formData.append("banner", banner);

		// Definición de la función fetchData
		const fetchData = async () => {
			try {
				const res = await axios.post(
					`${process.env.REACT_APP_API_URL}/auth/users/`,
					formData,
					config
				);
				if (res.status === 201) {
					Swal.fire("Usuario creado exitosamente", "", "success");
					navigate("/users/all");
					actions.setSubmitting(false);
					actions.resetForm();
					setActiveStep(0);
				}
			} catch (error) {
				if (
					error.response &&
					error.response.status === 400 &&
					error.response.data.email &&
					error.response.data.email.includes("Ya existe user account con este email.")
				) {
					Swal.fire("Ya existe un usuario registrado con este E-mail.");
				} else if (
					error.response &&
					error.response.status === 400 &&
					error.response.data.username &&
					error.response.data.username.includes("Ya existe user account con este username.")
				) {
					Swal.fire("Ya existe un usuario registrado con este nombre de usuario.");
				} else if (
					error.response &&
					error.response.status === 400 &&
					error.response.data.nuip &&
					error.response.data.nuip.includes("Ya existe user account con este nuip.")
				) {
					Swal.fire("Ya existe un usuario registrado con este numero de documento.");
				} else if (
					error.response &&
					error.response.status === 400 &&
					error.response.data.non_field_errors &&
					error.response.data.non_field_errors.includes(
						"El contenido de los dos campos de contraseña no coincide."
					)
				) {
					Swal.fire("El contenido de los dos campos de contraseña no coincide.");
				} else if (
					error.response &&
					error.response.status === 400 &&
					error.response.data.password &&
					Array.isArray(error.response.data.password) &&
					error.response.data.password.length > 0
				) {
					Swal.fire(error.response.data.password[0], "", "error");
				} else {
					Swal.fire("Ha ocurrido un error, intenta nuevamente.");
				}
				setActiveStep(0);
			}
		};

		fetchData();
		
	};

	const handleSubmit = (values, actions) => {
		if (isLastStep) {
			submitForm(values, actions);
		} else {
			setActiveStep(activeStep + 1);
			actions.setTouched({});
			actions.setSubmitting(false);
		}
	};

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<SoftBox py={3} mb={20}>
				<Grid container justifyContent="center" sx={{ height: "100%" }}>
					<Grid item xs={12} lg={8}>
						<Stepper activeStep={activeStep} alternativeLabel>
							{steps.map((label) => (
								<Step key={label}>
									<StepLabel>{label}</StepLabel>
								</Step>
							))}
						</Stepper>
						<Formik
							initialValues={initialValues}
							validationSchema={currentValidation}
							onSubmit={handleSubmit}
						>
							{({ values, errors, touched, isSubmitting, setFieldValue }) => (
								<Form id={formId} autoComplete="off">
									<Card sx={{ overflow: "visible" }}>
										<SoftBox p={2}>
											<SoftBox>
												{getStepContent(
													activeStep,
													{
														values,
														touched,
														formField,
														errors,
														setFieldValue,
													},
													user
												)}
												<SoftBox mt={2} width="100%" display="flex" justifyContent="space-between">
													{activeStep === 0 ? (
														<SoftBox />
													) : (
														<SoftButton variant="gradient" color="light" onClick={handleBack}>
															Anterior
														</SoftButton>
													)}
													<SoftButton
														disabled={isSubmitting}
														type="submit"
														variant="gradient"
														color="dark"
													>
														{isLastStep ? "Enviar" : "Siguiente"}
													</SoftButton>
												</SoftBox>
											</SoftBox>
										</SoftBox>
									</Card>
								</Form>
							)}
						</Formik>
					</Grid>
				</Grid>
			</SoftBox>
			<Footer />
		</DashboardLayout>
	);
}

export default NewUser;
