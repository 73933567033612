import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App"; // Asegúrate de que la ruta a App sea correcta

// Soft UI Context Provider
import { SoftUIControllerProvider } from "./context"; // Asegúrate de que la ruta a context sea correcta

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container); // Crea una raíz para tu aplicación.

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <SoftUIControllerProvider>
        <App />
      </SoftUIControllerProvider>
    </BrowserRouter>
  </React.StrictMode>
);
