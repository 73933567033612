// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// EditProduct page components

import ProfileInfo from "layouts/pages/profile/edit-profile/components/ProfileInfo";
import Socials from "layouts/pages/profile/edit-profile/components/Socials";

import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import { get_profile } from "redux/actions/user/user";

function EditProfile({ get_profile, profile}) {

	const params = useParams();
	const id = params.uid;

	useEffect(() => {
		get_profile(id);
	}
	, [get_profile, id]);

	const [profileData, setProfileData] = useState({
		document_type_enterprise: "",
		nuip_enterprise: "",
		rut: "",
		description: "",
		phone: "",
		address : "",
		facebook : "",
		instagram : "",
		X : "",
	});
	const handleProfileDataChange = (field, value) => {
		setProfileData((prevData) => ({
			...prevData,
			[field]: value,
		}));
	};

	const handleSave = () => {
		const config = {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };
		const formData = new FormData();
		formData.append("document_type_enterprise", profileData.document_type_enterprise.value);
		formData.append("nuip_enterprise", profileData.nuip_enterprise);
		formData.append("rut", profileData.rut);
		formData.append("description", profileData.description);
		formData.append("phone", profileData.phone);
		formData.append("address", profileData.address);
		formData.append("facebook", profileData.facebook);
		formData.append("instagram", profileData.instagram);
		formData.append("X", profileData.X);

		formData.append("id", id);
		const fetchData = async () => {
      try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/enterprise/profile/edit/${id}/`,
				formData,
				config);
				
				if (res.status === 200) {
					Swal.fire("Perfil editado", "El perfil se ha editado correctamente", "success");
				}
				else {
					Swal.fire("Error", "Ha ocurrido un error", "error");
				}
			}
			catch (err) {
				Swal.fire("Error", "Ha ocurrido un error", "error");
			}
		}
		fetchData();
	}
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {
				profile && profile ? (
					<SoftBox my={3}>
        <SoftBox mb={3}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6}>
              <SoftTypography variant="h4" fontWeight="medium">
                Editar perfil de empresa
              </SoftTypography>
              <SoftBox mt={1} mb={2}>
                <SoftTypography variant="body2" color="text">
                  Modifica la información de la empresa
                </SoftTypography>
              </SoftBox>
            </Grid>
            <Grid item xs={12} lg={6}>
              <SoftBox display="flex" justifyContent="flex-end">
                <SoftButton variant="gradient" color="info" onClick={handleSave}>
                  Guardar
                </SoftButton>
              </SoftBox>
            </Grid>
          </Grid>
        </SoftBox>
        <Grid container spacing={3}>
          {/* <Grid item xs={12} lg={4}>
            <ProfileImage />
          </Grid> */}
          <Grid item xs={12} lg={8}>
            <ProfileInfo profile={profile} onProfileChange={handleProfileDataChange} />
          </Grid>
          <Grid item xs={12} lg={4}>
            <Socials profile={profile} onProfileChange={handleProfileDataChange} />
          </Grid>
        </Grid>
      </SoftBox>
				) : "1"
			}
      <Footer />
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => ({
	profile: state.users.profile,
});

export default connect(mapStateToProps, { get_profile })(EditProfile);
