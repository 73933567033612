// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import ProductCell from "./components/ProductCell";
import ActionCell from "./components/ActionCell";
import SoftBadge from "components/SoftBadge";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { productsList, productsListPage } from "redux/actions/products/products"
import { connect } from "react-redux";

const inactive = (
	<SoftBadge variant="contained" color="error" size="xs" badgeContent="inactivo" container />
);
const active = (
	<SoftBadge variant="contained" color="success" size="xs" badgeContent="activo" container />
);

function ProductsList({ productsList, productsListPage, products }) {

	useEffect(() => {
		productsList();
		
	}, [productsList]);

	const user = useSelector((state) => state.auth.user) || {};

	const dataTableData = {
		columns: [
			{
				Header: "Beneficio",
				accessor: "beneficio",
				width: "40%",
				// Modifica la celda para devolver un Link que envuelva el contenido
				Cell: ({ value: [name, data] }) => (
					<Link to={`/product-details/${data.id}`}> {/* Asumiendo que data tiene una propiedad id */}
						<ProductCell image={data.image} name={name} />
					</Link>
				),
			},
			{ Header: "Descripción", accessor: "descripcion" },
			{
				Header: "Estado",
				accessor: "status",
				Cell: ({ value }) => (value === "activo" ? active : inactive),
			},
			...(user.role === "enterprise" || user.role === "Admin"
        ? [
            {
              Header: "Acción",
              accessor: "action",
              Cell: ({ row }) => 
                <ActionCell edit={row.original.action} />
           
            },
          ]
        : []),
		],
		rows: products ? products.map(product => ({
			beneficio: [product.name, product],
			descripcion: product.description,
			status: product.finished === false ? "activo" : "inactivo",
			action: product.id,
		})) : [],
	};
	

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<SoftBox my={3}>
				<Card>
					<SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" p={3}>
						<SoftBox lineHeight={1}>
							<SoftTypography variant="h5" fontWeight="medium">
								Beneficio
							</SoftTypography>
							<SoftTypography variant="button" fontWeight="regular" color="text">
								Lista de los productos o servicios que tienen descuento
							</SoftTypography>
						</SoftBox>
						<Stack spacing={1} direction="row">
							{user && user.role === "enterprise" || user && user.role === "Admin" ? (
								<Link to="/product-new">
									<SoftButton variant="gradient" color="success" size="small">
										+ nuevo beneficio
									</SoftButton>
								</Link>
							) : null}

							{/* <SoftButton variant="outlined" color="info" size="small">
                import
              </SoftButton>
              <SoftButton variant="outlined" color="info" size="small">
                export
              </SoftButton> */}
						</Stack>
					</SoftBox>
					<DataTable
						table={dataTableData}
						entriesPerPage={{
							defaultValue: 5,
							entries: [5, 7, 10, 15, 20, 25],
						}}
						canSearch
					/>
				</Card>
			</SoftBox>
			<Footer />
		</DashboardLayout>
	);
}

const mapStateToProps = (state) => ({
	products: state.products.products,
});

export default connect(mapStateToProps, { productsList, productsListPage })(ProductsList);
