import {
	GET_JOB_SUCCESS,
	GET_JOB_FAIL,
	JOBS_LIST_SUCCESS,
	JOBS_LIST_FAIL,
} from "../actions/jobs/types";

const initialState = {
	job: null,
	jobs: null,
	count: null,
	next: null,
	previous: null,
};

export default function jobs(state = initialState, action) {
	const { type, payload } = action;
	
	switch	(type) {
		case JOBS_LIST_SUCCESS:
			return {
				...state,
				jobs: payload.results.jobs,
				count: payload.count,
				next: payload.next,
				previous: payload.previous,
			};
		case JOBS_LIST_FAIL:
			return {
				...state,
				jobs: null,
				count: null,
				next: null,
				previous: null,
			};
		case GET_JOB_SUCCESS:
			return {
				...state,
				job: payload.job,
			};
		case GET_JOB_FAIL:
			return {
				...state,
				job: null,
			};
		default:
			return state;
	}
}