// Soft UI Dashboard PRO React layouts
import AnalyticsDashboard from "layouts/pages/profile/teams";
import Benefit from "layouts/ecommerce/products/products-list";
import Job from "layouts/ecommerce/jobs/jobs-list";
import Users from "layouts/dashboards/users/reports";
import UsersRegister from "layouts/dashboards/users/new-user";
import AllJobs from "layouts/pages/profile/all-jobs";
import AllCompanies from "layouts/pages/profile/all-companies";

import Dashboard from "examples/Icons/Dashboard";

import UsersGroup from "examples/Icons/UsersGroup";
import Percent from "examples/Icons/Percent";
import Suitcase from "examples/Icons/Suitcase";
import Cover from "layouts/authentication/sign-in/cover";

const routes = [
	{
		name: "Login",
		key: "login",
		route: "/",
		component: <Cover />,
	},
	{
    type: "collapse",
    name: "Inicio",
    key: "dashboard",
    icon: <Dashboard size="18px" />,
    collapse: [
			{
				name: "Beneficios",
				key: "benefits",
				route: "/dashboard/benefits",
				component: <AnalyticsDashboard />,
			},
			{
				name: "Oferta de empleos",
				key: "jobs",
				route: "/dashboard/jobs",
				component: <AllJobs />,
			},
			{
				name: "Empresas",
				key: "companies",
				route: "/dashboard/companies",
				component: <AllCompanies />,
			},
		],
  },
	{
    type: "collapse",
    name: "Beneficios",
    key: "benefits",
    route: "/benefits",
		component: <Benefit />,
    icon: <Percent size="18px" />,
    noCollapse: true,
  },
	{
    type: "collapse",
    name: "Bolsa de empleo",
    key: "job-board",
    route: "/job-board",
		component: <Job />,
    icon: <Suitcase size="18px" />,
    noCollapse: true,
  },
	{
		type: "collapse",
		name: "Usuarios",
		key: "users",
		icon: <UsersGroup size="18px" />,
		collapse: [
			{
				name: "Lista de usuarios",
				key: "all",
				route: "/users/all",
				component: <Users />,
			},
			{
				name: "Registro",
				key: "register",
				route: "/users/register",
				component: <UsersRegister />,
			},
		],
	},
	{ type: "divider", key: "divider-1" },
];

export default routes;


