import { useState, useEffect, Suspense } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";

// Soft UI Dashboard PRO React example components
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

import { Tooltip, Icon } from "@mui/material";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import ProfileHeader from "components/Skeletons/ProfileHeader";
import SoftButton from "components/SoftButton";
import ModalCardCIE from "components/ModalCard/ModalCardCIE";

function Header({ user, enterprise }) {
	const [tabsOrientation, setTabsOrientation] = useState("horizontal");
	const [tabValue, setTabValue] = useState(0);

	useEffect(() => {
		// A function that sets the orientation state of the tabs.
		function handleTabsOrientation() {
			return window.innerWidth < breakpoints.values.sm
				? setTabsOrientation("vertical")
				: setTabsOrientation("horizontal");
		}

		/** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
		window.addEventListener("resize", handleTabsOrientation);

		// Call the handleTabsOrientation function to set the state with the initial value.
		handleTabsOrientation();

		// Remove event listener on cleanup
		return () => window.removeEventListener("resize", handleTabsOrientation);
	}, [tabsOrientation]);

	const handleSetTabValue = (event, newValue) => setTabValue(newValue);

	const userprofile = useSelector((state) => state.auth.user);
	return (
		<SoftBox position="relative">
			<DashboardNavbar absolute light />

			{user ? (
				<>
					<SoftBox
						display="flex"
						alignItems="center"
						position="relative"
						minHeight="18.75rem"
						borderRadius="xl"
						sx={{
							backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
								`${linearGradient(
									rgba(gradients.dark.main, 0.3),
									rgba(gradients.dark.state, 0.3)
								)}, url(${user.banner})`,
							backgroundSize: "cover",
							backgroundPosition: "50%",
							overflow: "hidden",
						}}
					/>
					<Card
						sx={{
							backdropFilter: `saturate(200%) blur(30px)`,
							backgroundColor: ({ functions: { rgba }, palette: { white } }) =>
								rgba(white.main, 0.8),
							boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
							position: "relative",
							mt: -8,
							mx: 3,
							py: 2,
							px: 2,
						}}
					>
						<Suspense fallback={<ProfileHeader />}>
							<Grid container spacing={3} alignItems="center">
								<Grid item>
									<SoftAvatar
										src={user.picture}
										alt="profile-image"
										variant="rounded"
										size="xl"
										shadow="sm"
									/>
								</Grid>
								<Grid item>
									<SoftBox height="100%" mt={0.5} lineHeight={1}>
										<SoftTypography variant="h5" fontWeight="medium">
											{user.first_name} {user.last_name}
										</SoftTypography>
										<SoftTypography variant="button" color="text" fontWeight="medium">
											{user.enterprise} / {user.role === "employees" ? "Empleado" : "Empresa"}
										</SoftTypography>
									</SoftBox>
								</Grid>
								<Grid item>
									<ModalCardCIE name={`${user.first_name} ${user.last_name}`} enterprise={user.enterprise} />
								</Grid>
							</Grid>
						</Suspense>
					</Card>
				</>
			) : enterprise && enterprise ? (
				<>
					<SoftBox
						display="flex"
						alignItems="center"
						position="relative"
						minHeight="18.75rem"
						borderRadius="xl"
						sx={{
							backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
								`${linearGradient(
									rgba(gradients.dark.main, 0.3),
									rgba(gradients.dark.state, 0.3)
								)}, url(${enterprise.user.banner})`,
							backgroundSize: "cover",
							backgroundPosition: "50%",
							overflow: "hidden",
						}}
					/>
					<Card
						sx={{
							backdropFilter: `saturate(200%) blur(30px)`,
							backgroundColor: ({ functions: { rgba }, palette: { white } }) =>
								rgba(white.main, 0.8),
							boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
							position: "relative",
							mt: -8,
							mx: 3,
							py: 2,
							px: 2,
						}}
					>
						<SoftBox display="flex" alignItems="center" justifyContent="space-between">
							<Grid container spacing={3} alignItems="center">
								<Grid item>
									<SoftAvatar
										src={enterprise.user.picture}
										alt="profile-image"
										variant="rounded"
										size="xl"
										shadow="sm"
									/>
								</Grid>
								<Grid item>
									<SoftBox height="100%" mt={0.5} lineHeight={1}>
										<SoftTypography variant="h5" fontWeight="medium">
											{enterprise.user.first_name} {enterprise.user.last_name}
										</SoftTypography>
										<SoftTypography variant="button" color="text" fontWeight="medium">
											{enterprise.user.enterprise} /{" "}
											{enterprise.user.role === "employees" ? "Empleado" : "Empresa"}
										</SoftTypography>
									</SoftBox>
								</Grid>
							</Grid>
							{userprofile && userprofile.id === enterprise.user.id ? (
								<SoftTypography
									component={Link}
									to={`/profile-edit/${enterprise.user.id}`}
									variant="body2"
									color="secondary"
								>
									<Tooltip title="Editar" placement="left">
										<Icon>edit</Icon>
									</Tooltip>
								</SoftTypography>
							) : null}
						</SoftBox>
					</Card>
				</>
			) : (
				<SoftBox
					display="flex"
					alignItems="center"
					position="relative"
					minHeight="18.75rem"
					borderRadius="xl"
					sx={{
						backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
							`${linearGradient(
								rgba(gradients.dark.main, 0.3),
								rgba(gradients.dark.state, 0.3)
							)}, url(${""})`,
						backgroundSize: "cover",
						backgroundPosition: "50%",
						overflow: "hidden",
					}}
				/>
			)}
		</SoftBox>
	);
}

export default Header;
