// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import ActionCell from "./components/ActionCell";
import SoftBadge from "components/SoftBadge";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { jobsList, jobsListPage } from "redux/actions/jobs/jobs";
import { connect } from "react-redux";
import ProductCell from "layouts/ecommerce/jobs/jobs-list/components/ProductCell";

const inactive = (
	<SoftBadge variant="contained" color="error" size="xs" badgeContent="inactivo" container />
);
const active = (
	<SoftBadge variant="contained" color="success" size="xs" badgeContent="activo" container />
);
function JobsList({ jobsList, jobsListPage, jobs }) {
	useEffect(() => {
		jobsList();
	}, [jobsList]);

	const user = useSelector((state) => state.auth.user) || {};

  const shouldShowAction = (jobUserId) => {
    // Verifica si el usuario es un Admin o el creador del trabajo (enterprise)
    return user.role === 'Admin' || (user.role === 'enterprise' && user.id === jobUserId);
  };


	const dataTableData = {
		columns: [
			{
				Header: "Trabajo",
				accessor: "trabajo",
				width: "40%",
				Cell: ({ value: [name, data] }) => 
				<Link to={`/job-details/${data.id}`}>
					<ProductCell image={data.image} name={name} />
				</Link>
				,
			},
			{ Header: "Descripción", accessor: "descripcion" },
			{
				Header: "Estado",
				accessor: "status",
				Cell: ({ value }) => (value === "draft" ? inactive : active),
			},
      ...(user.role === "enterprise" || user.role === "Admin"
        ? [
            {
              Header: "Acción",
              accessor: "action",
              Cell: ({ row }) => 
                <ActionCell edit={row.original.action} />

            },
          ]
        : []),
		],

		rows: jobs
			? jobs.map((job) => ({
					trabajo: [job.title, job],
					descripcion: job.description,
					status: job.status,
					action: job.id,
			  }))
			: [],
	};

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<SoftBox my={3}>
				<Card>
					<SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" p={3}>
						<SoftBox lineHeight={1}>
							<SoftTypography variant="h5" fontWeight="medium">
								Bolsa de empleo
							</SoftTypography>
							<SoftTypography variant="button" fontWeight="regular" color="text">
								Listado de tus solicitudes de empleo
							</SoftTypography>
						</SoftBox>
						<Stack spacing={1} direction="row">
							{user && user.role === "enterprise"  || user && user.role === "Admin" ? (
								<Link to="/job-new">
									<SoftButton variant="gradient" color="success" size="small">
										+ nuevo trabajo
									</SoftButton>
								</Link>
							) : null}
							{/* <SoftButton variant="outlined" color="info" size="small">
                import
              </SoftButton>
              <SoftButton variant="outlined" color="info" size="small">
                export
              </SoftButton> */}
						</Stack>
					</SoftBox>
					<DataTable
						table={dataTableData}
						entriesPerPage={{
							defaultValue: 5,
							entries: [5, 7, 10, 15, 20, 25],
						}}
						canSearch
					/>
				</Card>
			</SoftBox>
			<Footer />
		</DashboardLayout>
	);
}

const mapStateToProps = (state) => ({
	jobs: state.jobs.jobs,
});

export default connect(mapStateToProps, { jobsList, jobsListPage })(JobsList);
