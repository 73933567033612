import {
	GET_USER_DASHBOARD_SUCCESS,
	GET_USER_DASHBOARD_FAIL,
	GET_PRODUCT_DASHBOARD_SUCCESS,
	GET_PRODUCT_DASHBOARD_FAIL,
	GET_JOB_DASHBOARD_SUCCESS,
	GET_JOB_DASHBOARD_FAIL,
} from "./types";

import axios from "axios";

export const get_user_dashboard = () => async (dispatch) => {
	try {
		const config = {
			headers: {
				Accept: "application/json",
				Authorization: `JWT ${localStorage.getItem("access")}`,
			},
		};
		const res = await axios.get(`${process.env.REACT_APP_API_URL}/user/dashboard/`, config);
		if (res.status === 200) {
			dispatch({
				type: GET_USER_DASHBOARD_SUCCESS,
				payload: res.data,
			});
		} else {
			dispatch({
				type: GET_USER_DASHBOARD_FAIL,
			});
		}
	} catch (err) {
		dispatch({
			type: GET_USER_DASHBOARD_FAIL,
		});
	}
};

export const get_product_dashboard = () => async (dispatch) => {
	try {
		const config = {
			headers: {
				Accept: "application/json",
				Authorization: `JWT ${localStorage.getItem("access")}`,
			},
		};
		const res = await axios.get(`${process.env.REACT_APP_API_URL}/product/dashboard/`, config);
		if (res.status === 200) {
			dispatch({
				type: GET_PRODUCT_DASHBOARD_SUCCESS,
				payload: res.data,
			});
		} else {
			dispatch({
				type: GET_PRODUCT_DASHBOARD_FAIL,
			});
		}
	} catch (err) {
		dispatch({
			type: GET_PRODUCT_DASHBOARD_FAIL,
		});
	}
};

export const get_job_dashboard = () => async (dispatch) => {
	try {
		const config = {
			headers: {
				Accept: "application/json",
				Authorization: `JWT ${localStorage.getItem("access")}`,
			},
		};
		const res = await axios.get(`${process.env.REACT_APP_API_URL}/job/dashboard/`, config);
		if (res.status === 200) {
			dispatch({
				type: GET_JOB_DASHBOARD_SUCCESS,
				payload: res.data,
			});
		} else {
			dispatch({
				type: GET_JOB_DASHBOARD_FAIL,
			});
		}
	} catch (err) {
		dispatch({
			type: GET_JOB_DASHBOARD_FAIL,
		});
	}
};
