// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import curved6 from "assets/images/curved-images/curved6.jpg";
import { useEffect, useState } from "react";
import { refresh, check_authenticated, load_user, reset_password } from "redux/actions/auth/auth";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

function Cover({ reset_password, isAuthenticated, refresh, check_authenticated, load_user }) {
	const navigate = useNavigate();
	useEffect(() => {
		if (!isAuthenticated) {
			refresh();
			check_authenticated();
			load_user();
		}
	}, [refresh, check_authenticated, load_user, isAuthenticated]);
	

	const [formData, setFormData] = useState({
		email: "",
	});

	const { email } = formData;

	const onChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};
	

	const onSubmit = (e) => {
		e.preventDefault();
		reset_password(email);
	};

	if (isAuthenticated) {
		navigate("/");
	}
	return (
		<CoverLayout
			title="Restaurar contraseña"
			description="Recibirás un correo electrónico con un enlace para restaurar tu contraseña."
			image={curved6}
			top={30}
		>
			<SoftBox
				component="form"
				role="form"
				onSubmit={(e) => {
					onSubmit(e);
				}}
			>
				<SoftBox mb={2} lineHeight={1.25}>
					<SoftBox mb={1} ml={0.5}>
						<SoftTypography component="label" variant="caption" fontWeight="bold">
							Correo electrónico
						</SoftTypography>
					</SoftBox>
					<SoftInput
						type="email"
						placeholder="Ingresa tu correo"
						value={email}
						name="email"
						onChange={(e) => onChange(e)}
					/>
				</SoftBox>
				<SoftBox mt={4} mb={1}>
					<SoftButton type="submit" variant="gradient" color="success" fullWidth>
						Restaurar contraseña
					</SoftButton>
				</SoftBox>
			</SoftBox>
		</CoverLayout>
	);
}

const mapStateToProps = (state) => ({
	isAuthenticated: state.auth.isAuthenticated,
	loading: state.auth.loading,
});

export default connect(mapStateToProps, {
	reset_password,
	refresh,
	check_authenticated,
	load_user,
})(Cover);
