// @mui material components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { Link } from "react-router-dom";

function ActionCell({ edit }) {

  return (
    <SoftBox display="flex" alignItems="center">
			<Link to={`/product-details/${edit}`}>
      <SoftTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }}>
        <Tooltip title="Ver beneficio" placement="top">
          <Icon>visibility</Icon>
        </Tooltip>
      </SoftTypography>
			</Link>
      <SoftBox mx={2}>
				<Link to={`/product-edit/${edit}`}>
        <SoftTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }}>
          <Tooltip title="Editar beneficio" placement="top">
            <Icon>edit</Icon>
          </Tooltip>
        </SoftTypography>
				</Link>
      </SoftBox>
      <SoftTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }}>
        <Tooltip title="Eliminar beneficio" placement="left">
          <Icon>delete</Icon>
        </Tooltip>
      </SoftTypography>
    </SoftBox>
  );
}

export default ActionCell;
