// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
// NewUser page components
import FormField from "layouts/ecommerce/products/new-product/components/FormField";

import SoftSelect from "components/SoftSelect";

function ProductInfo({ formData }) {
	const { formField, values, errors, touched } = formData;
	const { name, description } = formField;
	const { name: nameV, description: descriptionV } = values;

	return (
		<SoftBox>
			<SoftBox lineHeight={0}>
				<SoftTypography variant="h5" fontWeight="bold">
					Información del Beneficio
				</SoftTypography>
			</SoftBox>
			<SoftBox mt={1.625}>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={6}>
						<FormField
							type={name.type}
							label={name.label}
							name={name.name}
							value={nameV}
							placeholder={name.placeholder}
							error={errors.name && touched.name}
							success={nameV.length > 0 && !errors.name}
							inputProps={{ autoComplete: "" }}
						/>
					</Grid>

					<Grid item xs={12} sm={12}>
						<FormField
							multiline
							rows={5}
							type={description.type}
							label={description.label}
							name={description.name}
							value={descriptionV}
							placeholder={description.placeholder}
							error={errors.description && touched.description}
							success={descriptionV.length > 0 && !errors.description}
							inputProps={{ autoComplete: "" }}
						/>
					</Grid>
				</Grid>
			</SoftBox>
		</SoftBox>
	);
}

// typechecking props for ProductInfo
ProductInfo.propTypes = {
	formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ProductInfo;
