import * as Yup from "yup";
import checkout from "layouts/ecommerce/products/new-product/schemas/form";

const {
	formField: { name, description },
} = checkout;

const validations = [
	Yup.object().shape({
		[name.name]: Yup.string().required(name.errorMsg),
		[description.name]: Yup.string().required(description.errorMsg),
	}),
];

export default validations;
