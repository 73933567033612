import { useState } from "react";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// NewUser page components
import JobInfo from "layouts/ecommerce/jobs/new-job/components/JobInfo";
import Multimedia from "layouts/ecommerce/jobs/new-job/components/Multimedia";

// NewUser layout schemas for form and form feilds
import validations from "layouts/ecommerce/jobs/new-job/schemas/validations";
import form from "layouts/ecommerce/jobs/new-job/schemas/form";
import initialValues from "layouts/ecommerce/jobs/new-job/schemas/initialValues";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
function getSteps() {
	return ["Información del Empleo", "Multimedia"];
}

function getStepContent(stepIndex, formData) {
	switch (stepIndex) {
		case 0:
			return <JobInfo formData={formData} />;
		case 1:
			return <Multimedia formData={formData} />;
		default:
			return null;
	}
}

function NewJob() {
	const [activeStep, setActiveStep] = useState(0);
	const navigate = useNavigate();
	const steps = getSteps();
	const { formId, formField } = form;
	const currentValidation = validations[activeStep];
	const isLastStep = activeStep === steps.length - 1;

	const sleep = (ms) =>
		new Promise((resolve) => {
			setTimeout(resolve, ms);
		});
	const handleBack = () => setActiveStep(activeStep - 1);

	const submitForm = async (values, actions) => {
		await sleep(1000); // Simula un retraso

		const config = {
			headers: {
				Authorization: `JWT ${localStorage.getItem("access")}`,
				Accept: "application/json",
			},
		};

		const formData = new FormData();
		formData.append("title", values.title);
		formData.append("description", values.description);
		formData.append("image", values.image);
		formData.append("priority", values.priority.value);
		const fetchData = async () => {
			try {
				const res = await axios.post(
					`${process.env.REACT_APP_API_URL}/job/create/`,
					formData,
					config
				);

				if (res.status === 200) {
					// Aquí manejas la respuesta exitosa
					Swal.fire("Beneficio registrado sastifactoriamente", "", "success");
					navigate("/job-board");
				}
				actions.setSubmitting(false);
				actions.resetForm();
				setActiveStep(0);
			} catch (error) {
				if (
					error.response &&
					error.response.status === 400 &&
					error.response.data.nuip.includes("Ya existe Pacificador con este nuip.")
				) {
					Swal.fire(
						"Ya estás registrado en el programa pacificador con ese número de cédula.",
						"",
						"error"
					);
					actions.setSubmitting(true);
					setActiveStep(0);
				} else {
					Swal.fire("Ha ocurrido un error", "", "error");
					actions.setSubmitting(true);
					setActiveStep(0);
				}
			}
		};
		fetchData();
	};

	const handleSubmit = (values, actions) => {
		if (isLastStep) {
			submitForm(values, actions);
		} else {
			setActiveStep(activeStep + 1);
			actions.setTouched({});
			actions.setSubmitting(false);
		}
	};

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<SoftBox py={3} mb={20}>
				<Grid container justifyContent="center" sx={{ height: "100%" }}>
					<Grid item xs={12} lg={8}>
						<Stepper activeStep={activeStep} alternativeLabel>
							{steps.map((label) => (
								<Step key={label}>
									<StepLabel>{label}</StepLabel>
								</Step>
							))}
						</Stepper>
						<Formik
							initialValues={initialValues}
							validationSchema={currentValidation}
							onSubmit={handleSubmit}
						>
							{({ values, errors, touched, isSubmitting, setFieldValue }) => (
								<Form id={formId} autoComplete="off">
									<Card sx={{ height: "100%" }}>
										<SoftBox p={2}>
											<SoftBox>
												{getStepContent(activeStep, {
													values,
													touched,
													formField,
													errors,
													setFieldValue,
												})}
												<SoftBox mt={2} width="100%" display="flex" justifyContent="space-between">
													{activeStep === 0 ? (
														<SoftBox />
													) : (
														<SoftButton variant="gradient" color="light" onClick={handleBack}>
															Anterior
														</SoftButton>
													)}
													<SoftButton
														disabled={isSubmitting}
														type="submit"
														variant="gradient"
														color="dark"
													>
														{isLastStep ? "Enviar" : "Siguiente"}
													</SoftButton>
												</SoftBox>
											</SoftBox>
										</SoftBox>
									</Card>
								</Form>
							)}
						</Formik>
					</Grid>
				</Grid>
			</SoftBox>
			<Footer />
		</DashboardLayout>
	);
}

export default NewJob;
