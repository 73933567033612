import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftEditor from "components/SoftEditor";
import SoftSelect from "components/SoftSelect";

// NewProduct page components
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";

function JobInfo({ job, onJobChange }) {
	const handleChange = (eventOrValue, fieldName) => {
		// Verifica si el argumento es un evento (tiene target) o un valor directo
		const value = eventOrValue.target ? eventOrValue.target.value : eventOrValue;
		onJobChange(fieldName, value);
	};

	const selectData = {
		priority: [
			{ value: "Baja", label: "Baja" },
			{ value: "Media", label: "Media" },
			{ value: "Alta", label: "Alta" },
		],
	};

	return (
		<Card sx={{ overflow: "visible" }}>
			<SoftBox p={3}>
				<SoftTypography variant="h5">Información</SoftTypography>
				<SoftBox mt={1}>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={6}>
							<FormField
								type="text"
								label="Titulo"
								defaultValue={job.title}
								onChange={(e) => handleChange(e, "title")}
							/>
						</Grid>

						<Grid item xs={12} sm={6}>
							<SoftTypography component="label" variant="caption" fontWeight="bold">
								Prioridad&nbsp;&nbsp;
							</SoftTypography>
							<SoftSelect
								defaultValue={{ value: job.priority, label: job.priority }}
								options={selectData.priority}
								onChange={(e) => handleChange(e, "priority")}
							/>
						</Grid>

						<Grid item xs={12} sm={12}>
							<FormField
								type="text"
								multiline
								rows={5}
								label="Descripcion"
								defaultValue={job.description}
								onChange={(e) => handleChange(e, "description")}
							/>
						</Grid>
					</Grid>
				</SoftBox>
			</SoftBox>
		</Card>
	);
}

export default JobInfo;
