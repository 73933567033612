/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import curved6 from "assets/images/curved-images/curved6.jpg";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";

function Cover() {
	const params = useParams();
	const uid = params.uid;
	const token = params.token;

	const navigate = useNavigate();

	const onClick = async () => {
		try {
			const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/users/activation/`, {
				uid,
				token,
			});
			if (res.status === 204) {
				Swal.fire("Cuenta activada correctamente", "", "success");
				navigate("/");
			}
		} catch (error) {
			if (
				error.response &&
				error.response.status === 403 &&
				error.response.data.detail &&
				error.response.data.detail.includes("El token del usuario ha expirado.")
			)
				Swal.fire("Error", "El token del usuario ha expirado.", "error");
			else {
				console.log(error);
				Swal.fire("Error", "Error al activar la cuenta", "error");
			}
		}
	};

	return (
		<CoverLayout
			title="Activar cuenta"
			description="Recbiste un correo electrónico con un código de activación."
			image={curved6}
			top={30}
		>
			<SoftBox mt={4} mb={1}>
				<SoftButton variant="gradient" color="success" fullWidth onClick={onClick}>
					Activar
				</SoftButton>
			</SoftBox>
		</CoverLayout>
	);
}

export default Cover;
