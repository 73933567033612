// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI dashboards PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";

// NewUser page components

function Multimedia({ formData }) {
	const { formField, values, errors, touched, setFieldValue } = formData;

	const { picture, banner } = formField;
	const { picture: pictureV, banner: bannerV } = values;

	// Handle file change
	const handleFileChangePicture = (e) => {
		const file = e.target.files[0];
		if (file) {
			setFieldValue("picture", file); // Update the form field
		}
	};
	const handleFileChangeBanner = (e) => {
		const file = e.target.files[0];
		if (file) {
			setFieldValue("banner", file); // Update the form field
		}
	};

	return (
		<SoftBox>
			<SoftBox lineHeight={0}>
				<SoftTypography variant="h5" fontWeight="bold">
					Multimedia
				</SoftTypography>
				<SoftTypography variant="button" fontWeight="regular" color="text">
					Imagenes logo y banner de la empresa
				</SoftTypography>
			</SoftBox>
			<SoftBox mt={1.625}>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={6}>
						<SoftTypography
							component="label"
							variant="caption"
							fontWeight="bold"
							textTransform="capitalize"
						>
							Logo de la empresa
						</SoftTypography>
						<SoftInput
							type="file" // Change to file type
							label={picture.label}
							name={picture.name}
							placeholder={picture.placeholder}
							error={errors.picture && touched.picture}
							success={values.picture && !errors.picture}
							inputProps={{ autoComplete: "" }}
							onChange={handleFileChangePicture} // Handle file change
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<SoftTypography
							component="label"
							variant="caption"
							fontWeight="bold"
							textTransform="capitalize"
						>
							Banner de la empresa
						</SoftTypography>
						<SoftInput
							type="file" // Change to file type
							label={banner.label}
							name={banner.name}
							placeholder={banner.placeholder}
							error={errors.banner && touched.banner}
							success={values.banner && !errors.banner}
							inputProps={{ autoComplete: "" }}
							onChange={handleFileChangeBanner} // Handle file change
						/>
					</Grid>
				</Grid>
			</SoftBox>
		</SoftBox>
	);
}

// typechecking props for Multimedia
Multimedia.propTypes = {
	formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Multimedia;
