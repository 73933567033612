import { useState, useEffect } from "react";

// react-router components
import { useLocation, Link, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";

// Soft UI Dashboard PRO React example components
// import Breadcrumbs from "examples/Breadcrumbs";

// Custom styles for DashboardNavbar
import {
	navbar,
	navbarContainer,
	navbarRow,
	navbarIconButton,
	navbarDesktopMenu,
	navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Soft UI Dashboard PRO React context
import {
	useSoftUIController,
	setTransparentNavbar,
	setMiniSidenav,
	setOpenConfigurator,
} from "context";

// Images
import team2 from "assets/images/team-2.jpg";
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";

import { check_authenticated, load_user, refresh, logout } from "redux/actions/auth/auth";
import Swal from "sweetalert2";
import { connect } from "react-redux";
function DashboardNavbar({
	absolute,
	light,
	isMini,
	refresh,
	check_authenticated,
	load_user,
	user_loading,
	user,
	logout,
	isAuthenticated,
}) {
	const [navbarType, setNavbarType] = useState();
	const [controller, dispatch] = useSoftUIController();
	const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator } = controller;
	const [openMenu, setOpenMenu] = useState(false);
	const route = useLocation().pathname.split("/").slice(1);

	useEffect(() => {
		refresh();
		check_authenticated();
		load_user();
	}, [check_authenticated, load_user, refresh]);

	useEffect(() => {
		// Setting the navbar type
		if (fixedNavbar) {
			setNavbarType("sticky");
		} else {
			setNavbarType("static");
		}

		// A function that sets the transparent state of the navbar.
		function handleTransparentNavbar() {
			setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
		}

		/** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
		window.addEventListener("scroll", handleTransparentNavbar);

		// Call the handleTransparentNavbar function to set the state with the initial value.
		handleTransparentNavbar();

		// Remove event listener on cleanup
		return () => window.removeEventListener("scroll", handleTransparentNavbar);
	}, [dispatch, fixedNavbar]);

	const navigate = useNavigate();

	const handleLogout = () => {
		logout();
		let timerInterval;
		Swal.fire({
			title: "¡Te has desautenticado correctamente!",
			icon: "success",
			timer: 2100,
  		timerProgressBar: true,
			didOpen: () => {
				Swal.showLoading();
				const timer = Swal.getPopup().querySelector("b");
				timerInterval = setInterval(() => {
					timer.textContent = `${Swal.getTimerLeft()}`;
				}, 2100);
			},
			willClose: () => {
				clearInterval(timerInterval);
			}
		}).then((result) => {
			/* Read more about handling dismissals below */
			if (result.dismiss === Swal.DismissReason.timer) {
				navigate("/");
				window.location.reload();
			}
		});
		redirectToLogin();
	};

	const redirectToLogin = async () => {
		await setTimeout(() => {
			navigate("/");
			window.location.reload();
		}, 2500);
	};
	
	const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
	const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
	const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
	const handleCloseMenu = () => setOpenMenu(false);

	// Render the notifications menu


	return (
		<AppBar
			position={absolute ? "absolute" : navbarType}
			color="inherit"
			sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
		>
			<Toolbar sx={(theme) => navbarContainer(theme)}>
				<SoftBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
					{/* <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} /> */}
					<Icon fontSize="medium" sx={navbarDesktopMenu} onClick={handleMiniSidenav}>
						{miniSidenav ? "menu_open" : "menu"}
					</Icon>
				</SoftBox>
				{isMini ? null : (
					<SoftBox sx={(theme) => navbarRow(theme, { isMini })}>
						{/* <SoftBox pr={1}>
              <SoftInput
                placeholder="Buscar"
                icon={{ component: "search", direction: "left" }}
              />
            </SoftBox> */}
						<SoftBox color={light ? "white" : "inherit"}>
							<IconButton
								size="small"
								color="inherit"
								sx={navbarIconButton}
								onClick={handleLogout} // Aquí usas directamente handleLogout
							>
								<Icon className={light ? "text-white" : "text-dark"}>account_circle</Icon>
								<SoftTypography
									variant="button"
									fontWeight="medium"
									color={light ? "white" : "dark"}
								>
									Logout
								</SoftTypography>
							</IconButton>
							<IconButton
								size="small"
								color="inherit"
								sx={navbarMobileMenu}
								onClick={handleMiniSidenav}
							>
								<Icon className={light ? "text-white" : "text-dark"}>
									{miniSidenav ? "menu_open" : "menu"}
								</Icon>
							</IconButton>
							{/* <IconButton
                size="small"
                color="inherit"
                sx={navbarIconButton}
                onClick={handleConfiguratorOpen}
              >
                <Icon>settings</Icon>
              </IconButton> */}
							{/* <IconButton
								size="small"
								color="inherit"
								sx={navbarIconButton}
								aria-controls="notification-menu"
								aria-haspopup="true"
								variant="contained"
								onClick={handleOpenMenu}
							>
								<Icon className={light ? "text-white" : "text-dark"}>notifications</Icon>
							</IconButton>
							{renderMenu()} */}
						</SoftBox>
					</SoftBox>
				)}
			</Toolbar>
		</AppBar>
	);
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
	absolute: false,
	light: false,
	isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
	absolute: PropTypes.bool,
	light: PropTypes.bool,
	isMini: PropTypes.bool,
};

const mapStateToProps = (state) => ({
	user_loading: state.auth.user_loading,
	user: state.auth.user,
	isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, {
	refresh,
	check_authenticated,
	load_user,
	logout,
})(DashboardNavbar);
