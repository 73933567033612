// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// ProductPage page components
import ProductImages from "layouts/ecommerce/products/product-page/components/ProductImages";
import ProductInfo from "layouts/ecommerce/products/product-page/components/ProductInfo";

// Data
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getProduct } from "redux/actions/products/products";
import { connect, useDispatch } from "react-redux";

function ProductPage({ getProduct, product }) {
	const params = useParams();
	const id = params.uid;
	const dispatch = useDispatch();
	const [shouldLoadProduct, setShouldLoadProduct] = useState(true);
	useEffect(() => {
    if (shouldLoadProduct) {
      dispatch({ type: 'GET_PRODUCT_FAIL' }); // Resetea el perfil a null
      getProduct(id); // Carga el perfil
      setShouldLoadProduct(false); // Restablece el indicador
    }
  }, [id, shouldLoadProduct, getProduct, dispatch]);

	useEffect(() => {
		if (product === null) {
			getProduct(id);
		}
	}, [id, getProduct, product]);

	useEffect(() => {
    setShouldLoadProduct(true);
  }, [id]);
	return (
		<DashboardLayout>
			<DashboardNavbar />
			{product && product ? (
				<SoftBox py={3}>
					<Card sx={{ overflow: "visible" }}>
						<SoftBox p={3}>
							<SoftBox mb={3}>
								<SoftTypography variant="h5" fontWeight="medium">
									Detalles del beneficio
								</SoftTypography>
							</SoftBox>

							<Grid container spacing={3}>
								<Grid item xs={12} lg={6} xl={5}>
									<SoftBox borderRadius="xl" maxHeight="450px" overflow="hidden" display="flex" justifyContent="center" alignItems="center">
										<ProductImages source={product.image} height="150%" />
									</SoftBox>
								</Grid>
								<Grid item xs={12} lg={5} sx={{ mx: "auto" }}>
									<ProductInfo
										title={product.name}
										description={product.description}
										created={product.created}
										enterprise={product.user}
										facebook={product.profile && product.profile.facebook ? product.profile.facebook : ""}
										instagram={product.profile.instagram  && product.profile.instagram ? product.profile.instagram : ""}
										X={product.profile.X && product.profile.X ? product.profile.X : ""}
										address={product.profile.address && product.profile.address ? product.profile.address : ""}

									/>
								</Grid>
							</Grid>
						</SoftBox>
					</Card>
				</SoftBox>
			) : (
				<SoftBox py={3}>
					<Card sx={{ overflow: "visible" }}>
						<SoftBox p={3}>
							<SoftBox mb={3}>
								<SoftTypography variant="h5" fontWeight="medium">
									Producto no encontrado
								</SoftTypography>
							</SoftBox>
						</SoftBox>
					</Card>
				</SoftBox>
			)}
			<Footer />
		</DashboardLayout>
	);
}

const mapStateToProps = (state) => ({
	product: state.products.product,
});

export default connect(mapStateToProps, { getProduct })(ProductPage);
